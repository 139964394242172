// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vn = {
  complete_infomation: 'Hãy hoàn tất thông tin của bạn để có trải nghiệm tốt nhất.',
  error: {
    required: 'Đây là trường bắt buộc',
    format: {
      email: 'Phải nhập đúng định dạng email',
      phone: 'Phải nhập đúng định dạng số điện thoại',
    },
  },
  demo: {
    title: `Vietnamese`,
    introduction: `Lorem Ipsum chỉ đơn giản là văn bản giả của ngành in ấn và sắp chữ. Lorem Ipsum đã trở thành văn bản giả tiêu chuẩn của ngành kể từ những năm 1500, khi một nhà in không xác định lấy một dãy loại và xáo trộn nó để tạo thành một cuốn sách mẫu. Nó đã tồn tại không chỉ năm thế kỷ, mà còn là bước nhảy vọt trong lĩnh vực sắp chữ điện tử, về cơ bản vẫn không thay đổi. Nó được phổ biến vào những năm 1960 với việc phát hành các tờ Letraset chứa các đoạn Lorem Ipsum, và gần đây hơn là với phần mềm xuất bản trên máy tính để bàn như Aldus PageMaker bao gồm các phiên bản của Lorem Ipsum.`,
  },
  docs: {
    hi: `Chào`,
    description: `Cần giúp đỡ？\n Vui lòng xem tài liệu của chúng tôi.`,
    documentation: `tài liệu`,
  },
  commons: {
    day: 'd',
    hour: 'h',
    minute: 'm',
    second: 's',
    package: 'Gói',
    expired: '{{name}} đã hết hạn',
  },
  modal: {
    btn: {
      back: 'Quay lại',
      close: 'Đóng',
      done: 'Hoàn tất',
      next: 'Tiếp theo',
    },
    popup: {
      linking_again: 'Liên kết lại',
    },
    title: {
      error_linking: 'Lỗi tài khoản liên kết',
    },
  },
  image_upload: {
    upload: 'Tải ảnh lên',
    delete: 'Xóa hết',
    update: 'Cập nhật ảnh',
  },
  pwa: {
    title: 'Tính năng nhận thông báo',
    step_one: `1. Bấm vào nút 'Chia sẻ'`,
    step_two: `2. Chọn 'Thêm vào màn hình chính'`,
    content: `
      1.Truy cập trang web của bạn trên trình duyệt Safari của bạn trên thiết bị di động Apple trên 16.4 trở lên.
      2.Nhấp vào nút Chia sẻ của trình duyệt Safari.
      3.Nhấp vào tùy chọn Thêm vào màn hình chính.
      4.Lưu ứng dụng trên thiết bị của bạn.
      5.Mở ứng dụng từ màn hình chính.
      6.Đăng ký nhận thông báo để sử dụng tính năng mới của chúng tôi.
    `,
  },
  form: {
    invalid_format: 'Vui lòng nhập đúng định dạng',
    min: 'Nhập ít nhất {{min}} ký tự',
    max: 'Nhập tối đa {{max}} ký tự',
    required: 'Đây là trường bắt buộc',
    array: {
      min: 'Phải có ít nhất {{min}}',
      max: 'Tối đa {{max}}',
    },
    image: {
      min: 'Tải lên ít nhất {{min}} ảnh',
      max: 'tải lên tối đa {{max}} ảnh',
    },
    nudity: 'Hình ảnh có chứa ảnh khoả thân',
  },
  source_filter: {
    private: 'Cá nhân',
    organization: 'Nội bộ',
    public: 'Cộng đồng',
    legit: 'Chính chủ',
    exclusive: 'Độc quyền',
    all: 'Tất cả',
    btn: {
      apply: 'Áp dụng',
      reset: 'Reset',
    },
  },
  partnership: {
    title: 'Đối tác',
    content: 'Tận hưởng giá trị, hiệu quả, và ưu đãi với tài khoản đối tác trong hệ sinh thái Uptin. ',
  },
  progress: 'Đang trong tiến trình, xin đợi vài giây!',
  feature_disable: 'Bạn không thể sử dụng tính năng này',
  asset_required: 'Bạn phải lưu nội dung trước khi sử dụng tính năng này',
  task_running: 'Tiến trình tương tự đang chạy vui lòng chờ!',
  login: {
    main_title: 'Nền tảng Marketing toàn diện tăng hiệu quả tiếp thị và đột phá doanh thu',
    heading: 'Đăng nhập - Đăng ký Uptin',
    heading_tenant: 'Đăng nhập {{name}}',
    sub_heading: 'Đăng nhập hoặc đăng ký nhanh với',
    by_email: 'Đăng nhập bằng email',
    remember: 'Nhớ  tài khoản',
    forgot: 'Quên mật khẩu',
    test_account: 'Tài khoản dùng thử : {{email}} / Mật khẩu : {{password}}',
    or: 'HOẶC',
    btn: {
      login: 'Đăng nhập',
    },
    form: {
      password: 'Mật khẩu',
    },
    message: {
      invalid: 'Email hoặc mật khẩu không đúng',
      suggest: 'Bạn có thể đăng nhập bằng Google thay cho Email và Mật Khẩu',
    },
  },
  forgot_password: {
    heading: 'Quên mật khẩu?',
    description:
      'Vui lòng nhập địa chỉ email được liên kết với tài khoản của bạn, chúng tôi sẽ gửi link hướng dẫn đặt lại mật khẩu.',
    form: {
      error: {
        email: {
          required: 'Email là bắt buộc',
          format: 'Phải nhập đúng định dạng email',
        },
      },
    },
    btn: {
      reset: 'Đặt lại mật khẩu',
      back: 'Quay lại',
    },
  },
  reset_password: {
    title: 'Yêu cầu đã gửi thành công!',
    form: {
      field: {
        password: 'Mật khẩu',
        re_password: 'Nhập lại mật khẩu',
      },
      error: {
        password: {
          required: 'Mật khẩu là bắt buộc',
        },
        re_password: {
          required: 'Nhập lại mật khẩu là bắt buộc',
          match: 'Mật khẩu nhập lại chưa khớp',
        },
      },
    },
    btn: {
      change: 'Đổi Mật Khẩu',
    },
  },
  confirm_staff: {
    title: 'Đang tiến hành kiểm tra...',
  },
  nav: {
    general: `Tổng quan`,
    asset: `Tin`,
    calendar: `Lịch`,
    analytic: `Thị trường`,
    push_notification: `Báo`,
    email_marketing: `Email marketing`,
    blog: `Hóng`,
    create_asset: `Soạn tin`,
    real_estate: `Bất động sản`,
    social: `Mạng xã hội`,
    web: 'Website',
    account: `Tài khoản`,
    setting: `Cài đặt`,
    payment: `Thanh toán`,
    change_password: `Đổi mật khẩu`,
    logout: `Đăng xuất`,
    staff: 'Nhân sự',
    campaign: 'Chiến dịch',
    feed: 'Nuôi',
    multi_channel: 'Tiếp thị',
  },
  header: {
    notification: {
      notification: 'Thông báo',
      unread: `Bạn có # thông báo chưa đọc`,
      all_notification: 'Xem tất cả thông báo',
    },
    task: {
      view_result: 'Nhấp vào để xem kết quả',
      empty: 'Chưa có kết quả',
      task_completed: 'Một số tiến trình đã hoàn thành!',
    },
  },
  component: {
    delete_account: {
      content: `ơi, điều gì xảy ra khi bạn "<span style="font-weight: bold">Xóa tài khoản</span>" ?`,
      note: {
        first: 'Uptin chỉ xóa tài khoản đăng nhập',
        second: 'Các tài khoản liên kết với các nền tảng vẫn giữ lại',
      },
      btn: {
        back: 'Quay lại',
        confirm: 'Xác nhận',
      },
    },
    trial: {
      group: 'Bạn làm việc nhóm?',
      enterprise: 'Bạn là chủ doanh nghiệp?',
      try_package: 'Dùng thử gói tháng',
      try_seven_days: 'Dùng thử {{days}} ngày',
      content: 'Số dư tài khoản của bạn có thể được dùng để thanh toán sau.',
      btn: {
        accept: 'Đồng ý',
        cancel: 'Hủy',
      },
    },
  },
  dashboard: {
    app: {
      welcome: 'Chào mừng bạn!',
      content: 'Chọn đăng tin hoặc làm nhiệm vụ để nhận tiền FREE nhé.',
      btn: {
        post: 'Đăng tin ngay',
        linking: 'Liên kết ngay',
        quest: 'Nhiệm vụ',
      },
      tab: {
        day: 'Ngày',
        month: 'Tháng',
      },
      chart: {
        total_post: 'Tổng tin',
        view: 'Lượt xem',
        display: 'Lượt hiển thị',
        statistics: 'Thống kê tháng',
        statistics_sub: 'so với tháng trước',
        spend: 'Thống kê chi tiêu',
        schedule_success: 'Tin đăng thành công',
        schedule_fail: 'Tin lỗi',
        balance: 'Số dư hiện tại',
        payment: 'Nạp',
        disburse: 'Chi tiêu',
        sell: 'Bán',
        rent: 'Thuê',
      },
    },
    account: {
      save: 'Lưu thay đổi',
      send_request: 'Gửi yêu cầu',
      breadcrumb: {
        home: 'Trang chủ',
        user: 'Người dùng',
        setting: 'Cài đặt',
      },
      tab: {
        general: 'Thông tin chung',
        payment: 'Thanh toán',
        sme: 'Gói doanh nghiệp',
        notification: 'Thông báo',
        social: 'Mạng xã hội',
        password: 'Mật khẩu',
      },
      general: {
        avatar: {
          upload: 'Tải ảnh lên',
          update_type: 'Cho phép ảnh đuôi *.jpeg, *.jpg, *.png',
          update_size: 'Dung lượng tối đa 1Mb',
        },
        public: 'Công khai hồ sơ',
        delete: 'Xóa tài khoản',
        form: {
          first_name: 'Tên',
          last_name: 'Họ',
          phone: 'Điện thoại di động',
          city: 'Tỉnh/Thành phố',
          district: 'Quận/Huyện',
          company: 'Công ty',
          position: 'Chức vụ',
        },
        required: 'Vui lòng điền vào đây',
        success: 'Cập nhật thành công!',
        error: 'Cập nhật không thành công!',
        delete_account: 'Xóa tài khoản thành công! Hệ thống sẽ chuyển về trang đăng nhập trong vài giây!',
      },
      payment: {
        recharge: 'Nạp',
        payment: 'Thanh toán',
        method: 'Hình thức thanh toán',
        wallet: 'Ví',
        history: 'Lịch sử mua dịch vụ',
        empty: 'Không có',
        add_method: 'Thêm cách thanh toán',
        topup: 'Nạp',
        proceed: 'Thanh toán',
        package: 'Gói',
        sme: {
          member: {
            exact: '{{numb}} người dùng',
            range: '{{from}} - {{to}} người dùng',
            smaller: 'Dưới {{numb}} người dùng',
            larger: '{{numb}}+ người dùng',
          },
          target: {
            group: 'Dành cho nhóm',
            sme: 'Dành cho doanh nghiệp SME',
            enterprise: 'Dành cho doanh nghiệp lớn',
          },
          month: 'Tháng',
          year: 'Năm',
          discount: ' (Giảm {{percent}}%)',
        },
      },
      notifications: {
        activity: {
          comment: 'Gửi email cho tôi khi ai đó nhận xét bài viết của tôi',
          feeling: 'Gửi email cho tôi khi ai đó bày tỏ cảm xúc bài viết của tôi',
          follow: 'Gửi email cho tôi khi có người theo dõi tôi',
        },
        application: {
          update: 'Tin tức và thông báo hệ thống',
          blog: 'Các bản cập nhật phần mềm hàng tuần và bài viết blog mới',
        },
        success: 'Cập nhật thành công!',
      },
      change_password: {
        old_password: 'Mật khẩu cũ',
        new_password: 'Mật khẩu mới',
        re_new_password: 'Nhập lại mật khẩu mới',
        error: {
          old_password_required: 'Mật khẩu cũ là bắt buộc',
          new_password_required: 'Mật khẩu mới là bắt buộc',
          new_password_length: 'Mật khẩu mới phải ít nhất 6 ký tự',
          re_new_password: 'Mật khẩu nhập lại chưa khớp',
        },
      },
    },
    analytic: {
      location: 'Vị trí',
      breadcrumb: {
        market: 'Thị trường',
        overview: 'Tổng quan',
      },
      form: {
        city: 'Tỉnh/Thành phố',
        district: 'Quận/Huyện',
        ward: 'Phường/Xã',
        error: {
          required: 'Vui lòng điền vào đây',
        },
      },
      btn: {
        search: 'Tra cứu thông tin',
      },
      tab: {
        sell: 'Bán',
        rent: 'Thuê',
        facade: 'Mặt tiền',
        alley: 'Trong hẻm',
      },
      chart: {
        compare: 'so với tháng trước',
        title: 'Thị trường trong 3 năm',
        average_price: 'Giá trung bình',
        number_posts: 'Lượng tin',
      },
      average_price: 'Giá trung bình',
      number_posts: 'Số lượng tin',
      compare: 'so với quý trước',
      helps: 'Phân tích giúp bạn',
      develop: 'Tính năng này đang trong quá trình phát triển',
    },
    asset: {
      breadcrumb: {
        asset: 'Tin',
        overview: 'Tổng quan',
      },
      stat: {
        success: 'Thành công',
        scheduled: 'Lên lịch',
        total: 'Tổng số tin',
        month: 'tin trong tháng',
        sum: 'tổng số tin',
        post_total: 'Tổng số bài viết',
        post_month: 'bài viết trong tháng',
        post_sum: 'tổng số bài viết',
      },
      btn: {
        add: 'Thêm',
        remove_filter: 'Bỏ lọc',
        real_estate: 'BĐS',
        social: 'MXH',
        edit: 'Chỉnh sửa',
        schedule: 'Đăng tin',
        detail: 'Chi tiết',
        web: 'Website',
        converts: {
          mxh: 'AI tạo tin MXH',
          web: 'AI tạo bài WEB',
          pnt: 'AI tạo tin PNT',
        },
      },
      filter: {
        type: 'Loại tin',
        start_day: 'Ngày bắt đầu',
        end_day: 'Ngày kết thúc',
        search: 'Tìm theo tiêu đề',
      },
      table: {
        head: {
          type: 'Loại tin',
          avatar: 'Hình đại diện',
          title: 'Tiêu đề',
          city: 'Tỉnh thành phố',
          created_day: 'Ngày tạo',
          updated_day: 'Cập nhật',
        },
      },
      detail: {
        title: 'Chi tiết tin',
        table: {
          head: {
            platform: 'Nền tảng',
            title: 'Tiêu đề',
            date: 'Ngày',
            display: 'Hiển thị',
            status: 'Tình trạng',
            view: 'Lượt xem',
            impression: 'Lượt tiếp cận',
          },
        },
        tab: {
          all: 'Tổng',
          active: 'Đang hiển thị',
          inactive: 'Chưa thanh toán',
          hidden: 'Đang ẩn tin',
          remove: 'Đã xóa',
        },
        btn: {
          back: 'Quay lại',
          link: 'Xem tin',
        },
        dialog: 'Lọc tin',
        notification: 'Thông báo',
        collapse: 'Thu gọn',
      },
      message: {
        cloned: 'Bài viết đã được clone',
      },
    },
    calendar: {
      form: {
        table: {
          head: {
            platform: 'Nền tảng',
            id_balance: 'ID/Số dư',
            select_package: 'Chọn gói',
            title_balance: 'Tiêu đề/Số dư',
            note: 'Ghi chú',
            package: 'Gói',
            date_post: 'Ngày đăng',
            day_post: 'Chọn ngày',
            time_post: 'Chọn giờ',
            name_avatar: 'Tên/Ảnh đại diện',
          },
          body: {
            your_page: 'Trang của bạn',
            your_group: 'Nhóm của bạn',
            other_group: 'Nhóm của người khác',
            personal: 'Cá nhân',
            organization: 'Tổ chức',
            instagram_page: 'Trang Instagram',
          },
        },
        btn: {
          add: 'Thêm',
          edit: 'Chỉnh sửa',
          back: 'Quay lại',
          done: 'Hoàn thành',
          link: 'Xem bài đăng',
        },
        tab: {
          real_estate: 'Bất động sản',
          social: 'Mạng xã hội',
          web: 'Website',
        },
        current_asset: 'Tin đang chọn',
        spam: 'Chúng tôi thêm 5 phút độ trễ giữa các tin để bạn không bị đánh dấu spam',
        collapse: 'Thu gọn',
        type: 'Loại tin',
      },
      breadcrumb: {
        home: 'Trang chủ',
        calendar: 'Lịch',
      },
      btn: {
        schedule: 'Lên lịch',
      },
      title: {
        dialog: 'Lọc tin',
        notification: 'Thông báo',
      },
      message: {
        selects: 'Chọn ít nhất 1 nền tảng để đăng tin',
        invalid_time: 'Vui lòng chọn đúng ngày giờ lên lịch',
      },
    },
    site: {
      action: {
        link_account: 'liên kết tài khoản BĐS',
      },
      breadcrumb: {
        home: 'Trang chủ',
        account: 'Tài khoản',
      },
      dialog: {
        cms_title: 'Liên kết nền tảng CMS',
      },
      popup: {
        title: 'Công bố từ GOOGLE API',
        content: `Ứng dụng này tuân thủ Chính sách Dữ liệu Người dùng của Google API Services, bao gồm các yêu cầu Sử dụng Giới hạn. Điều này có nghĩa là chúng tôi sẽ chỉ sử dụng dữ liệu bạn cung cấp cho chúng tôi để cung cấp các dịch vụ bạn đã yêu cầu và chúng tôi sẽ không chia sẻ dữ liệu của bạn với bất kỳ bên thứ ba nào mà không có sự đồng ý của bạn.</br></br>Bạn có thể tìm hiểu thêm về Chính sách Dữ liệu Người dùng của Google API Services tại đây: <a href='https://developers.google.com/terms/api-services-user-data-policy' target='_blank'>https://developers.google.com/terms/api-services-user-data-policy</a>.</br></br>Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào về các thực tiễn bảo mật của chúng tôi, vui lòng liên lạc với chúng tôi qua: admin@uptin.vn</br></br>Cảm ơn bạn đã sử dụng ứng dụng của chúng tôi!`,
      },
      popup_remove: {
        title: 'Xác nhận xóa tài khoản',
        content: `Bạn có chắc chắn muốn xóa tài khoản này hay không?`,
      },
      btn: {
        add: 'Thêm',
        real_estate: 'BĐS',
        social: 'MXH',
        collapse: 'Thu gọn',
        view: 'Xem',
        edit: 'Chỉnh sửa',
        connect: 'Kết nối',
        close: 'Đóng',
        save: 'Kiểm tra và lưu',
        analytic: 'Google Analytics (GA4)',
        confirm: 'Xác nhận',
        cancel: 'Hủy',
        remove: 'Xóa',
      },
      tab: {
        all: 'Tổng',
        real_estate: 'Website BĐS',
        social: 'Mạng xã hội',
        website: 'CMS',
      },
      form: {
        table: {
          head: {
            platform: 'Nền tảng',
            id_balance: 'ID/Số dư',
            name: 'Tên tài khoản',
            status: 'Tình trạng',
            name_avatar: 'Tên/Ảnh đại diện',
          },
          body: {
            connected: 'Đã kết nối',
            disconnect: 'Mất kết nối',
          },
        },
        field: {
          password: 'Mật khẩu',
          app_password: 'Mật khẩu ứng dụng',
          access_token: 'Token của ứng dụng',
          website: 'Địa chỉ website',
          blog_url: 'Đường dẫn blog',
          username: 'Tài khoản',
          is_branch: 'Tài khoản phụ/Doanh nghiệp',
          allow_create: 'Tự động tạo nếu chưa tồn tại',
          phone: 'Số điện thoại',
          name: 'Tên hiển thị',
        },
        error: {
          required: 'Vui lòng nhập vào đây',
          url_invalid: 'Vui lòng nhập đúng định dạng',
        },
      },
      message: {
        site_inactive: '{{name}} đang tạm ngưng hoạt động',
        analytic_success: 'Tích hợp google analytic thành công',
        remove_success: 'Xoá tài khoản thành công',
      },
    },
    social: {
      popup_facebook: {
        title: 'Công bố từ FACEBOOK API',
        content: `<ol><li>Xác thực và Ủy quyền: Uptin sẽ tích hợp các cơ chế xác thực của Meta để cho phép người dùng đăng nhập hoặc kết nối tài khoản Meta của họ một cách an toàn. Điều này liên quan đến việc thu thập các quyền cần thiết từ người dùng để truy cập vào hồ sơ của họ và quản lý các nhóm và trang của họ.</li>
  <li>Tích hợp API Nhóm: Uptin sẽ sử dụng API Nhóm để lấy danh sách các nhóm và trang mà người dùng là thành viên hoặc là quản trị viên.</li>
  <li>Sử dụng Điểm cuối publish_to_groups: Uptin có thể tích hợp điểm cuối publish_to_groups để cho phép người dùng đăng nội dung vào các nhóm hoặc trang cụ thể thông qua nền tảng của họ. Điều này bao gồm: Cho phép người dùng soạn và lên lịch đăng bài hoặc phương tiện truyền thông. Hỗ trợ việc lựa chọn những nhóm nơi nội dung sẽ được đăng. Đảm bảo rằng nội dung được tạo ra tuân thủ các tiêu chuẩn cộng đồng và chính sách của Meta trước khi bắt đầu quá trình xuất bản.</li>
</ol> `,
      },
      platform: 'Chọn nền tảng liên kết',
      message: {
        empty_page: 'Bạn chưa tham gia bất kỳ trang nào',
        empty_group: 'Bạn chưa tham gia bất kỳ nhóm nào',
        empty_installed: `Không tìm thấy thông tin về các nhóm đã thêm app Uptin`,
        empty_personal: 'Không tìm thấy thông tin về cá nhân',
        empty_organization: 'Không tìm thấy thông tin về các tổ chức',
      },
      tab: {
        fanpage: 'Trang',
        own_group: 'Nhóm của bạn',
        installed_group: 'Nhóm đã kết nối với Uptin',
        personal: 'Cá nhân',
        organization: 'Tổ chức',
      },
      btn: {
        prev: 'Quay lại',
        next: 'Tiếp theo',
        back: 'Quay lại',
        copy_link: 'Copy LINK nhóm',
        auto_linking: 'Tự liên kết',
        confirm: 'Xác nhận',
        cancel: 'Hủy',
      },
      form: {
        search: 'Tìm theo tên nhóm hoặc nhãn...',
      },
      snackbar: {
        connected: 'Kết nối thành công',
        copied: 'Đã copy đường dẫn!',
        removed: 'Xóa kết nối thành công',
      },
      popup: {
        content: `Để đăng được lên nhóm “<span style="font-weight: bold">#</span>”, bạn cần liên lạc với Quản trị viên nhóm và yêu cầu họ <span style="font-weight: bold">thêm ứng dụng Uptin</span> trong phần App của nhóm. Hãy tìm nhóm trên facebook hoặc copy link này và paste trên trình duyệt.`,
        remove_content: `Thực hiện thao tác xoá bỏ liên kết mạng xã hội khỏi Uptin?`,
      },
    },
    create_asset: {
      breadcrumb: {
        add: 'Thêm',
        home: 'Trang chủ',
        asset: 'Tin',
        create_asset: 'Soạn tin',
        edit_asset: 'Chỉnh sửa',
      },
      message: {
        form_invalid: 'Vui lòng điền đẩy đủ thông tin',
      },
      real_estate: {
        title_create: 'Soạn tin bất động sản',
        title_edit: 'Chỉnh sửa tin bất động sản',
        tab: {
          general: 'Thông tin cơ bản',
          detail: 'Thông tin mô tả',
        },
        btn: {
          next_step: 'Tiếp tục',
          next: 'Tiếp theo',
          save: 'Lưu',
          complete: '{{action}} và lên lịch',
          edit: 'Cập nhật',
          create: 'Tạo',
          amenitie: 'Tiện ích xung quanh',
          convince: 'Thuyết phục',
          back: 'Quay lại',
          close: 'Đóng',
          add_amenitie: 'Thêm vào nội dung',
        },
        message: {
          create_success: 'Tạo tin thành công',
          edit_success: 'Cập nhật tin thành công',
          check_error: 'Vui lòng kiểm tra lại thông tin: ',
        },
        form: {
          field: {
            asset_type: 'Loại hình bất động sản',
            asset_detail: 'Bất động sản chi tiết',
            city: 'Tỉnh/Thành phố',
            district: 'Quận/Huyện',
            ward: 'Phường/Xã',
            street: 'Đường/Phố',
            price: 'Giá(VNĐ)',
            roi_percentage: 'Tỷ suất lợi nhuận %',
            area: 'Diện tích (m2)',
            actual_area: 'Diện tích sử dụng (m2)',
            url: 'Đường dẫn',
            share_source: {
              title: 'Chia sẻ nguồn hàng',
              private: 'Cá nhân',
              organization: 'Nội bộ',
              public: 'Cộng đồng',
            },
            demand: {
              title: 'Nhu cầu*',
              sell: 'Cần bán',
              rent: 'Cho thuê',
              buy: 'Cần mua',
              tenant: 'Cần thuê',
              assign: 'Sang nhượng',
            },
            who: {
              title: 'Bạn là*',
              broker: 'Môi giới',
              owner: 'Chính chủ',
              developer: 'Nhà phát triển',
              investor: 'Nhà đầu tư',
            },
            bedroom: 'Phòng ngủ',
            toilet: 'Toilet',
            legal_document: 'Giấy tờ pháp lý',
            contact_info: 'Thông tin liên lạc',
            address: 'Số nhà',
            hand_overs: {
              project: 'Dự án',
              under_construction: 'Đang xây dựng',
              slacking: 'Chưa xong',
              handing_over: 'Đang bàn giao',
              complete: 'Hoàn thành',
              red_book: 'Sổ đỏ',
              house_ownership: 'Sổ hồng',
              sale_contract: 'Hợp đồng mua bán',
              valid_documents: 'Giấy tờ hợp lệ',
            },
            title: 'Tiêu đề',
            short_title: 'Ngắn (50-70 ký tự)',
            description: 'Mô tả chi tiết (tối đa 1500 ký tự)...',
            image: {
              title: 'Hình ảnh',
              heading: 'Tối thiểu 3 ảnh - Tối đa {{asset_image_count}} ảnh',
              first_content: '- Kéo thả hoặc nhấn chọn ảnh',
              second_content: '- Dung lượng ảnh tối đa 3Mb',
              third_content: '- Ảnh đầu tiên từ trái qua phải sẽ là ảnh bìa',
            },
            hidden: 'Ẩn',
            house_number_hidden: 'Ẩn số nhà khi đăng tin',
            shown: 'Hiển thị',
            house_number_shown: 'Hiển thị số nhà khi đăng tin',
            converts: {
              mxh: 'Tự động tạo bài viết mạng xã hội',
              web: 'Tự động tạo bài viết website',
              pnt: 'Tự động tạo bài "BÁO"',
            },
          },
          error: {
            select: 'Vui lòng chọn',
            required: 'Vui lòng điền vào đây',
            price: 'Vui lòng nhập giá đúng',
            area: 'Vui lòng nhập diện tích đúng',
            toilet: 'Vui lòng nhập toilet đúng',
            bedroom: 'Vui lòng nhập phòng ngủ đúng',
            url_invalid: 'Vui lòng điền đường dẫn hợp lệ',
            roi_percentage: {
              min: 'Tỷ suất lợi nhuận tối thiểu 0%',
              max: 'Tỷ suất lợi nhuận cao nhất là 30%',
            },
            short_title: {
              min: 'Tiêu đề tối thiểu 50 ký tự',
              max: 'Tiêu đề tối đa 70 ký tự',
            },
            description: {
              min: 'Mô tả chi tiết tối thiểu 200 ký tự',
              max: 'Mô tả chi tiết tối đa 1500 ký tự',
            },
            images: {
              min: 'Vui lòng tải lên tối thiểu 3 ảnh',
              max: 'Vui lòng tải lên tối đa {{asset_image_count}} ảnh',
            },
          },
        },
      },
      social: {
        title_create: 'Soạn tin mạng xã hội',
        title_edit: 'Chỉnh sửa tin mạng xã hội',
        btn: {
          rewrite: 'Viết lại',
          save: 'Lưu',
          complete: '{{action}} và lên lịch',
          edit: 'Cập nhật',
          create: 'Tạo',
          google_format: 'Định dạng bài Google',
          instagram_format: 'Định dạng bài Instagram',
        },
        message: {
          create_success: 'Tạo tin thành công',
          edit_success: 'Cập nhật tin thành công',
          check_error: 'Vui lòng kiểm tra lại thông tin: ',
          hashtag_error: 'Thêm một số từ khóa liên quan đến vấn đề của bạn',
        },
        form: {
          field: {
            keyword: 'Từ khóa, cách nhau bởi dấu phẩy',
            title: 'Tiêu đề',
            description: 'Hãy điền gì đó thật thú vị...',
            image: {
              title: 'Hình ảnh',
              heading: 'Tải lên tối đa {{asset_image_count}} ảnh',
              first_content: '-Kéo thả hoặc nhấn chọn ảnh',
              second_content: '-Dung lượng ảnh tối đa 3Mb',
              third_content: '-Ảnh đầu tiên từ trái qua phải sẽ là ảnh bìa',
            },
            converts: {
              mxh: 'Tự động tạo bài viết mạng xã hội',
              web: 'Tự động tạo bài viết website',
              pnt: 'Tự động tạo bài "BÁO"',
            },
          },
          error: {
            required: 'Vui lòng điền vào đây',
            description: {
              min: 'Điền ít nhất 50 ký tự',
              invalid: 'Nội dung phải nhiều hơn 50 ký tự (không tính biểu tượng cảm xúc)',
            },
            images: {
              max: 'Vui lòng tải lên tối đa {{asset_image_count}} ảnh',
            },
          },
        },
      },
      web: {
        title_create: 'Soạn tin website',
        title_edit: 'Chỉnh sửa tin website',
        btn: {
          preview: 'Xem trước',
          complete: 'Hoàn thành',
        },
        message: {
          create_success: 'Tạo tin thành công',
          edit_success: 'Cập nhật tin thành công',
          check_error: 'Vui lòng kiểm tra lại thông tin: ',
        },
        form: {
          field: {
            title: 'Tiêu đề',
            description: 'Mô tả',
            content: 'Nhập nội dung',
            tags: 'Tags',
            meta_title: 'Tiêu đề meta',
            meta_description: 'Mô tả meta',
            meta_keywords: 'Từ khóa',
            template: 'Template',
            image: {
              title: 'Ảnh bìa',
              heading: 'Ảnh bìa',
              first_content: '- Kéo thả hoặc nhấn chọn ảnh',
              second_content: '- Dung lượng ảnh tối đa 5Mb',
            },
            converts: {
              mxh: 'Tự động tạo bài viết mạng xã hội',
              web: 'Tự động tạo bài viết website',
              pnt: 'Tự động tạo bài "BÁO"',
            },
          },
          error: {
            required: 'Vui lòng điền vào đây',
            title: {
              min: 'Điền ít nhất 30 ký tự',
              max: 'Điền tối đa 50 ký tự',
            },
            description: {
              min: 'Điền ít nhất 50 ký tự',
              invalid: 'Nội dung phải nhiều hơn 50 ký tự (không tính biểu tượng cảm xúc)',
            },
            images: {
              max: 'Vui lòng tải lên tối đa 1 ảnh',
              min: 'Vui lòng tải lên tối thiểu 1 ảnh',
            },
            meta_keywords: {
              min: 'Phải có ít nhất 1 từ khóa',
            },
            tags: {
              limit: 'Số lượng tag đã vượt quá giới hạn cho phép',
              min: 'Phải có ít nhất 1 tag',
            },
            template: {
              max: 'Template tối đa 200 ký tự',
            },
          },
        },
      },
    },
    notification: {
      you: 'Bạn',
      breadcrumb: {
        general: 'Tổng quan',
        notification: 'Thông báo',
        all_notification: 'Tất cả thông báo',
      },
      menu: {
        setting: 'Cài đặt thông báo',
        mark_read: 'Đánh dấu tất cả là đã đọc',
      },
      btn: {
        collapse: 'Thu gọn',
      },
    },
    quest: {
      breadcrumb: {
        quest: 'Nhiệm vụ',
        general: 'Tổng quan',
        user: 'Người dùng',
      },
      detail: 'Chi tiết nhiệm vụ:',
      multi: 'số tiền',
      vnd: 'đ',
      members: 'T.viên',
      title: {
        reward_login_daily: 'Đăng nhập hằng ngày',
        reward_daily_scheduled_bds: 'Đăng 1 tin BĐS',
        reward_daily_scheduled_mxh: 'Đăng 1 tin MXH',
        reward_daily_scheduled_web: 'Đăng 1 tin WEB',
        reward_linking_account: 'Liên kết thành công 1 tài khoản',
        promo_linking_facebook_group: 'Thêm Uptin vào nhóm Facebook',
        promo_daily_topup: 'Nạp lần đầu trong ngày',
        promo_first_topup: 'Nạp lần đầu',
      },
      description: {
        reward_login_daily:
          '- Phần thưởng: {{moneyReceive}} đ\n- Yêu cầu: Đăng nhập vào Uptin thông qua Google hoặc Facebook\n- Loại nhiệm vụ: Mỗi ngày',
        reward_daily_scheduled_bds:
          '- Phần thưởng:  {{moneyReceive}} đ\n- Yêu cầu: Lên lịch cho 01 tin BĐS thành công trong ngày\n- Loại nhiệm vụ: Mỗi ngày',
        reward_daily_scheduled_mxh:
          '- Phần thưởng:  {{moneyReceive}} đ\n- Yêu cầu: Lên lịch cho 01 tin MXH thành công trong ngày\n- Loại nhiệm vụ: Mỗi ngày',
        reward_daily_scheduled_web:
          '- Phần thưởng:  {{moneyReceive}} đ\n- Yêu cầu: Lên lịch cho 01 tin WEB thành công trong ngày\n- Loại nhiệm vụ: Mỗi ngày',
        reward_linking_account:
          '- Phần thưởng: {{moneyReceive}} đ\n- Yêu cầu: Kết nối 1 tài khoản BĐS/MXH lần đầu tiên vào Uptin và thành công.\n- Loại nhiệm vụ: Một lần duy nhất',
        promo_linking_facebook_group:
          '- Phần thưởng: {{moneyReceive}} đ x Số lượng thành viên nhóm\n- Yêu cầu: Là người kết nối đầu tiên với nhóm đã cài đặt ứng dụng Uptin trong hệ thống. Nhóm phải có ít nhất 100 thành viên.\n- Loại nhiệm vụ: Một lần duy nhất',
        promo_daily_topup:
          '- Phần thưởng:  x{{moneyReceive}} số tiền nạp (Thưởng tối đa 50.000 đ)\n- Yêu cầu: Thanh toán qua ví Momo, VNPay hoặc chuyển khoản qua Ngân hàng\n- Loại nhiệm vụ: Mỗi ngày',
        promo_first_topup:
          '- Phần thưởng: x{{moneyReceive}} số tiền nạp (không giới hạn)\n- Yêu cầu: Thanh toán qua ví Momo, VNPay hoặc chuyển khoản qua Ngân hàng\n- Loại nhiệm vụ: Một lần duy nhất',
      },
      status: {
        completed: 'Hoàn thành',
      },
    },
    staff: {
      breadcrumb: {
        staff: 'Nhân sự',
        general: 'Tổng quan',
        list: 'Danh sách',
      },
      table: {
        head: {
          avatar: 'Ảnh',
          name: 'Họ tên',
          status: 'Trạng thái',
          date_join: 'Ngày tham gia',
          date_end: 'Ngày kết thúc',
        },
        body: {
          pending: 'Chờ duyệt',
          confirmed: 'Đang sử dụng',
          quit: 'Ngưng sủ dụng',
          refuse: 'Từ chối',
        },
      },
      form: {
        error: {
          required: 'Vui lòng điền vào đây',
          type_invalid: 'Vui lòng điền đúng định dạng',
        },
      },
      btn: {
        request: 'Gửi yêu cầu',
        add: 'Thêm nhân sự',
        accept: 'Đồng ý',
        collapse: 'Thu gọn',
        delete: 'Loại bỏ',
        resend: 'Mời lại',
      },
      delete: {
        title: 'Xoá nhân sự {{email}} khỏi gói dịch vụ?',
        content:
          'Người dùng trên sẽ không dùng chung gói của bạn nữa. Tuy nhiên, bạn sẽ chỉ có thể thêm nhân sự mới sau 7 ngày.',
      },
    },
    push_notification: {
      breadcrumb: {
        notification: 'Thông báo',
        overview: 'Tổng quan',
        create_notification: 'Tạo thông báo',
      },
      tab: {
        list: 'Danh sách',
        approve: 'Phê duyệt',
        audience: 'Đối tượng',
        content: 'Nội dung',
      },
      table: {
        head: {
          image: 'Ảnh',
          title: 'Tiêu đề',
          status: 'Tình trạng',
          budget: 'Ngân sách <br /> người nhận',
          date_send: 'Ngày gửi',
          content: 'Nội dung',
        },
      },
      form: {
        label: {
          demographic: 'Nhân khẩu học',
          date_picker: 'Chọn ngày',
        },
        field: {
          area: {
            title: 'Khu vực',
            northern: 'Miền Bắc',
            central: 'Miền Trung',
            southern: 'Miền Nam',
          },
          city: 'Tỉnh/Thành phố',
          career: {
            title: 'Nghề nghiệp',
            broker: 'Môi giới',
            owner: 'Chính chủ',
            developer: 'Nhà phát triển',
            investor: 'Nhà đầu tư',
          },
          asset_type: 'Loại hình bất động sản',
          asset_detail: 'Bất động sản chi tiết',
          demand: {
            title: 'Chuyên',
            sell: 'Cần bán',
            rent: 'Cho thuê',
            buy: 'Cần mua',
            tenant: 'Cần thuê',
            assign: 'Sang nhượng',
          },
          all: 'Tất cả',
          image: {
            heading: 'Tải ảnh lên',
            first_content: '- Kéo thả hoặc nhấn chọn ảnh',
            second_content: '- Dung lượng ảnh tối đa 5Mb',
            third_content: '- Ảnh có kích thước 256x256 px',
          },
          title: 'Tiêu đề(6-80 ký tự)',
          content: 'Nội dung(10-150 ký tự)',
          link: 'Đường link thông báo',
        },
        error: {
          required: 'Vui lòng điền vào đây',
          image: {
            required: 'Vui lòng tải ảnh lên',
            only_one: 'Vui lòng tải lên 1 ảnh',
            format: 'Ảnh phải có kích thước 256x256 px',
          },
          title: {
            min: 'Nhập ít nhất 6 ký tự',
            max: 'Nhập tối đa 80 ký tự',
          },
          content: {
            min: 'Nhập ít nhất 10 ký tự',
            max: 'Nhập tối đa 150 ký tự',
          },
          link: {
            format: 'Vui lòng nhập đúng định dạng link',
          },
        },
      },
      preview: 'Xem trước',
      btn: {
        delete: 'Xóa',
        approve: 'Duyệt',
        reject: 'Từ chối',
        collapse: 'Thu gọn',
        create_notification: 'Tạo thông báo',
        next: 'Tiếp tục',
        back: 'Quay lại',
        complete: 'Hoàn thành',
      },
      status: {
        deliveried: 'Đã phân phối',
        canceled: 'Từ chối',
        pending: 'Chờ duyệt',
        scheduled: 'Lên lịch',
      },
      message: {
        create_success: 'Tạo thông báo thành công',
        deleted_success: 'Xóa thông báo thành công',
        deleted_fail: 'Xóa thông báo thất bại',
        approved_success: 'Phê duyệt thông báo thành công',
        approved_fail: 'Phê duyệt thông báo thất bại',
        denied_success: 'Từ chối thông báo thành công',
        denied_fail: 'Từ chối thông báo thất bại',
      },
    },
    multi_channel: {
      steps: {
        source: 'Nguồn hàng',
        content: 'Nội dung',
        channels: 'Kênh phân phối',
        schedule: 'Lên lịch',
        btn: {
          next: 'Tiếp theo',
          back: 'Quay lại',
          save: 'Lưu',
          skip: 'Bỏ qua',
        },
        step_1: {
          industry: 'Ngành',
          exclusive: 'Độc quyền',
          industry_list: {
            real_estate: 'Bất động sản',
            ecommerce: 'Thương mại điện tử',
          },
          level: 'Chế độ',
          source_name: 'Tên nguồn hàng',
          data_from: 'Lấy dữ liệu từ tin',
          form: {
            images: {
              title: 'Tải lên 1 ảnh',
              text1: '- Kéo thả hoặc nhấn chọn ảnh',
              text2: '- Dung lượng ảnh tối đa 3Mb',
              text3: '- Ảnh đại diện của nguồn hàng',
            },
            errors: {
              required: 'Đây là trường bắt buộc',
              images: {
                min: 'Chọn ít nhất 1 ảnh',
                max: 'Chọn tối đa 1 ảnh',
              },
            },
          },
          message: {
            select_source: 'Vui lòng chọn nguồn hàng',
            create_source_success: 'Thêm nguồn hàng thành công',
          },
          btn: {
            add: 'Thêm nguồn',
          },
        },
        step_3: {
          cost: 'Chi phí:',
          setting_name: 'Tên cài đặt',
          selected: 'Đã chọn',
          quota: 'Hạn mức sử dụng',
          package: 'Gói',
          types: {
            bds: 'Bất động sản',
            mxh: 'Mạng xã hội',
            email: 'Email marketing',
            web: 'Trang web/Landing pages',
          },
          form: {
            errors: {
              required: 'Đây là trường bắt buộc',
            },
          },
          btn: {
            create_setting: 'Tạo cài đặt',
            add_setting: 'Thêm cài đặt',
            add_email: 'Thêm email',
          },
          message: {
            select_channel: 'Vui lòng chọn kênh phân phối',
            select_platform: 'Vui lòng chọn ít nhất 1 nền tảng',
            select_package: 'Vui lòng chọn gói phù hợp',
            create_setting_success: 'Tạo tùy chỉnh thành công',
          },
        },
        step_4: {
          right_away: 'Ngay bây giờ',
          later: 'Hẹn giờ',
          on: 'Vào lúc',
          confirm: 'Xác nhận gửi',
          message: {
            select_time: 'Vui lòng chọn thời gian phân phối',
          },
        },
      },
    },
    feed: {
      btn: {
        back: 'Quay lại',
        next: 'Tiếp tục',
        save: 'Hoàn thành',
        add: 'Thêm đối tượng',
      },
      step_1: {
        title: 'Đối tượng',
        filter: {
          type: 'Loại',
          keywords: 'Tìm theo tên',
          all: 'Tất cả',
          page: 'Trang',
          group: 'Nhóm',
          website: 'Website',
        },
        add_affiliate_account: 'Thêm tài khoản liên kết',
        linking_social_media: 'Liên kết nền tảng mạng xã hội',
        linking_cms: 'Liên kết nền tảng CMS',
      },
      step_2: {
        title: 'Cài đặt',
        messages: {
          select_sources: 'Vui lòng chọn ít nhất 1 nguồn',
          double_check: 'Vui lòng kiểm tra lại thông tin',
          limit_keyword: 'Từ khóa tối đa 200 ký tự',
          select_days: 'Vui lòng chọn ít nhất 1 ngày trong tuần',
          created_success: 'Đã kích hoạt chức năng nuôi thành công',
          server_error: 'Có lỗi xảy ra',
        },
        source: {
          title: 'Chọn nguồn:',
        },
        keyword: {
          title: 'Từ khóa:',
        },
        recurrence: {
          title: 'Vòng lặp:',
        },
        form: {
          fields: {
            days: 'Ngày',
            keyword: 'Từ khóa',
            time: 'Vào',
            end: 'Kết thúc',
            ai: {
              title: 'Giọng văn AI',
              random: 'Ngẫu nhiên',
              add_hashtag: 'Thêm Hashtag',
              add_emoji: 'Thêm Emoji',
              standard: 'Tiêu chuẩn',
              funny: 'Vui vẻ',
              creative: 'Sáng tạo',
              professional: 'Chuyên nghiệp',
            },
            days_in_week: {
              monday: '2',
              tuesday: '3',
              wednesday: '4',
              thursday: '5',
              friday: '6',
              saturday: '7',
              sunday: 'CN',
            },
          },
          errors: {
            required: 'Đây là trường bắt buộc',
            days: {
              min: 'Chọn ít nhất 1 ngày',
            },
            keywords: {
              min: 'Nhập tối thiểu 1 từ khóa',
              max: 'Nhập tối đa 5 từ khóa',
            },
          },
        },
      },
    },
  },
  email: {
    sidebar: {
      compose: 'Soạn',
      contact: 'Liên lạc',
      template: 'Template',
      campaigns: 'Chiến dịch',
      settings: 'Cài đặt',
      quota: 'Mức sử dụng',
    },
    btn: {
      create_campaign: 'Tạo tin email',
      create_group: 'Thêm nhóm liên lạc',
      create_contact: 'Thêm thông tin liên lạc',
      create_template: 'Tạo template',
      update: 'Cập nhật',
      create: 'Tạo',
      cancel: 'Hủy bỏ',
      check_verify: 'Kiểm tra',
      add_verify: 'Thêm người gửi',
      delete: 'Xoá',
      resend: 'Gửi lại',
      edit: 'Chỉnh sửa',
      schedule: 'Lên lịch',
      send_now: 'Gửi ngay',
      save: 'Lưu',
    },
    tabs: {
      domain_verify: 'Xác minh tên miền',
      email_verify: 'Xác minh email',
    },
    table: {
      head: {
        domain: 'Tên miền',
        verified: 'Tình trạng',
        bounce_domain: 'Tên miền trả lại',
        email: 'Email',
      },
      body: {
        verified: 'Đã xác minh',
        unverified: 'Chưa xác minh',
      },
    },
    message: {
      error: 'Có lỗi xảy ra',
      remove_success: 'Xóa thành công',
      resend_success: 'Gửi lại mail thành công',
    },
    verify: {
      btn: {
        continue: 'Tiếp tục',
        completed: 'Hoàn thành',
        back: 'Quay lại',
        verify: 'Xác minh',
        value: 'Value',
        copy: 'Copy',
      },
      email: {
        btn: {
          resend: 'Gửi lại',
        },
        form: {
          errors: {
            email_format: 'Vui lòng nhập đúng định dạng email',
            required: 'Đây là trường bắt buộc',
          },
        },
        content: {
          step1: {
            title: 'Nhập địa chỉ email',
            content: 'Nhập địa chỉ email bạn muốn sử dụng làm email gửi của mình.',
          },
          step2: {
            title: 'Kiểm tra hộp thư',
            content:
              'Chúng tôi đã gửi cho bạn một email xác minh. Kiểm tra hộp thư của bạn và nhấp vào liên kết xác nhận. Nếu bạn không nhận được nó, hãy kiểm tra các thư mục hộp thư khác hoặc nhấp vào nút gửi lại.',
            resend: 'Gửi lại email',
          },
        },
      },
      domain: {
        form: {
          errors: {
            email_format: 'Vui lòng nhập đúng định dạng email',
            domain_format: 'Vui lòng nhập đúng định dạng tên miền',
            required: 'Đây là trường bắt buộc',
          },
        },
        content: {
          dns: {
            title: 'Được rồi! Hãy làm theo hướng dẫn được mô tả bên dưới.',
            content: {
              spf: {
                existed: {
                  item1: `Chỉnh sửa bản ghi <span style="font-weight:bold">{{bold_text1}}</span> và hợp nhất bản ghi hiện có của bạn
              ghi lại và hợp nhất <span style="font-weight:bold">{{bold_text2}}</span> hiện có của bạn với hiển thị biến bên dưới`,
                  item2: `Ví dụ: nếu miền của bạn đã có bản ghi:`,
                  item3: `Vì vậy, biến được tạo sẽ trông như thế này:`,
                },
                no_content: {
                  item1: `Tuyệt vời! Hãy làm theo hướng dẫn được mô tả bên dưới.`,
                  item2: `Tạo bản ghi <span style="font-weight:bold">{{bold_text1}}</span> mới`,
                  item3: `Điền vào trường <span style="font-weight:bold">{{bold_text1}}</span>. Tùy thuộc vào nhà cung cấp, bạn có thể phải làm theo một trong các tùy chọn sau:`,
                  item4: `Để trống`,
                  item5: 'Điền nó bằng @',
                  item6: 'Nhập tên miền của bạn ({{domainName}})',
                  item7:
                    'Điền vào <span style="font-weight:bold">{{bold_text1}}</span> của bản ghi này với biến được hiển thị bên dưới',
                },
                mainContent: {
                  item1: ` Kiểm tra xem bạn đã có bản ghi <span style="font-weight:bold">{{bold_text1}}</span> trong DNS của mình chưa. Nó phải là một bản ghi loại <span style="font-weight:bold">{{bold_text2}}</span> với các biến SPF (bắt đầu bằng "v=spf1"). Việc kiểm tra này sẽ xác định các bước tiếp theo của bạn. ghi`,
                  item2: 'Tôi không tìm thấy bản ghi được mô tả ở trên',
                  item3: 'Tôi đã tìm thấy bản ghi được mô tả ở trên',
                },
              },
              other: {
                keyword: `Tạo bản ghi <span style="font-weight:bold">{{bold_text}}</span> mới`,
                host: `Điền vào trường <span style="font-weight:bold">Tên (Máy chủ)</span> của bản ghi này với biến được hiển thị bên dưới`,
                value: `Điền vào trường <span style="font-weight:bold">Value</span> của bản ghi này với biến được hiển thị bên dưới`,
              },
            },
          },
          step1: {
            title: 'Nhập tên miền bạn muốn xác minh',
          },
          step2: {
            title: 'Đăng nhập vào nhà cung cấp tên miền của bạn',
            content: `Để xác minh miền của bạn, bạn cần đặt bản ghi DNS cho miền của mình.`,
            list: {
              item1: `Mở tab mới và điều hướng và <span style="font-weight:bold">{{bold_text}}</span>
                  tới nền tảng nơi tên miền của bạn được lưu trữ`,
              item2: `Tìm màn hình nơi bạn có thể quản lý miền của mình. Sẽ có một tùy chọn để điều chỉnh <span style="font-weight:bold">{{bold_text}}</span>. của bạn`,
              item3: `Khi bạn tìm thấy nó, bạn đã sẵn sàng cho bước tiếp theo!`,
            },
          },
          step3: {
            title: 'Thêm mục vào cài đặt DNS của bạn',
            content: ` Trong cài đặt miền của bạn, bạn cần thêm bản ghi cho {{domain_name}}.`,
          },
          step4: {
            title: 'Xác nhận',
            content1: `Tuyệt vời! Tên miền của bạn đã được xác minh! Bây giờ bạn có thể tận hưởng nền tảng này bằng cách gửi email của mình từ bất kỳ địa chỉ nào được liên kết với miền này.`,
            content2: `Ví dụ: bạn có thể gửi email từ company@ hoặc info@. Tùy bạn!`,
          },
          step5: {
            title: 'Đặt người gửi mặc định',
            content: `Người gửi mặc định là địa chỉ email được sử dụng trong email của bạn dưới dạng địa chỉ 'Từ'. Chúng tôi đặc biệt khuyên bạn nên đặt người gửi mặc định để giúp tăng hiệu suất gửi thư của mình.`,
          },
          step6: {
            title: 'Thành công!',
            content: `Tuyệt vời! Miền của bạn hiện đã được xác minh và người gửi mặc định đã được đặt.`,
          },
        },
      },
    },
    template: {
      messages: {
        update_success: 'Cập nhật template thành công',
        create_success: 'Tạo template thành công',
      },
    },
    contact: {
      tabs: {
        overview: 'Tổng quan',
        groups: 'Nhóm',
        all_contacts: 'Tất cả liên lạc',
      },
      form: {
        create_group: 'Tạo nhóm',
        group_name: 'Tên nhóm',
        group: 'Nhóm',
        last_name: 'Họ',
        first_name: 'Tên',
        step1: 'Thêm thông tin chi tiết',
        step2: 'Chọn nhóm liên hệ',
        each_one: 'Thêm từng liên hệ',
        import: 'Nhập danh sách liên hệ. Tải file mẫu tại đây',
        csv: {
          heading: 'Chọn CSV file',
          title: 'Gồm 3 cột (last_name, first_name, email)',
          example: 'File mẫu',
          start: 'Bắt đầu',
        },
        errors: {
          required: 'Đây là trường bắt buộc',
          select_file: 'Vui lòng chọn file',
          select_group: 'Vui lòng chọn nhóm liên hệ cần thêm vào',
        },
        messages: {
          group_success: 'Tạo nhóm thành công',
          contact_success: 'Tạo liên lạc thành công',
          import_success: 'Thêm danh sách liên hệ thành công',
        },
      },
      table: {
        head: {
          recipient: 'Người nhận',
          status: 'Tình trạng',
          name: 'Tên',
          email: 'Email',
        },
        body: {
          active: 'Hoạt động',
          subscribe: 'Đã đăng ký',
          unsubscribe: 'Hủy đăng ký',
          deactive: 'Ngưng hoạt động',
          deleted: 'Đã xóa',
        },
      },
      messages: {
        remove_contact_success: 'Xóa contact thành công',
        remove_group_success: 'Xoá nhóm thành công',
      },
      overview: {
        summary: 'Bảng tóm tắt',
        all_contacts: 'Tất cả liên lạc',
        new_contacts: 'Liên lạc mới',
        subscribed: 'Đã đăng ký',
        contact_today: 'Liên lạc mới trong ngày',
        unsubscribed: 'Hủy đăng ký',
        open_rate: 'Tỉ lệ mở',
        click_rate: 'Tỉ lệ click',
        recent_growth: 'Tăng trưởng gần đây',
        change: `Những thay đổi về đối tượng trong {{range}} ngày qua`,
        performance: 'Hiệu suất của người nhận',
        growth: 'Liên hệ tăng trưởng',
        form: 'Xây dựng mối quan hệ. Phát triển danh sách của bạn với các tính năng của chúng tôi và tạo biểu mẫu hoặc trang đích để thu thập địa chỉ liên hệ mới',
        data: 'Số liên hệ của ngày',
        dropdown: '{{range}} ngày trước',
      },
    },
    campaign: {
      table: {
        head: {
          name: 'Tên',
          delivered: 'Lượt nhận',
          opened: 'Lượt mở',
          clicked: 'Lượt click',
        },
      },
      form: {
        send_to: 'Gửi đến',
        summary: 'Mục tiêu',
        summary_content_b2c:
          'Chiến dịch của bạn sẽ được gửi đến <span style="font-weight:bold;font-size:18px">{{recipients}}</span> người nhận. Số tiền chi trả cho chiến dịch là <span style="font-weight:bold;font-size:18px">{{money}}</span>',
        summary_content_b2b:
          'Chiến dịch của bạn sẽ được gửi đến <span style="font-weight:bold;font-size:18px">{{recipients}}</span> người nhận.',
        subject_content: 'Tiêu đề và nội dung',
        fromEmail: 'Email gửi',
        content: 'Nội dung',
        template: 'Chọn từ các mẫu được lưu trên tài khoản của bạn',
        setting: 'Cài đặt và theo dõi',
        tracking: 'Theo dõi',
        campaignName: 'Tên chiến dịch',
        tracks: {
          open: {
            title: 'Tìm hiểu chính xác số lần người nhận chú ý đến việc mở email liên lạc mà bạn đã gửi.',
            content: 'Theo dõi số lần mở',
          },
          click: {
            title: 'Theo dõi số lần nhấp chuột',
            content:
              'Tìm hiểu xem người nhận có đủ quan tâm không chỉ để mở chiến dịch của bạn mà còn tương tác với họ hay không.',
          },
          stats: {
            title: 'Nhận số liệu thống kê chiến dịch vào hộp thư email của bạn',
            content:
              'Bạn sẽ nhận được email có số liệu thống kê tổng thể về chiến dịch như tỷ lệ mở, tỷ lệ nhấp và tỷ lệ thoát trong vòng 48 giờ sau khi gửi.',
          },
        },
        errors: {
          required: 'Đây là trường bắt buộc',
          space: 'Không bao gồm khoảng trống',
          group: 'Vui lòng chọn nhóm cần gửi',
          format: 'Vui lòng nhập đúng định dạng',
          template: 'Vui lòng chọn template',
        },
      },
      messages: {
        update_success: 'Cập nhật chiến dịch thành công',
        create_success: 'Tạo chiến dịch thành công',
      },
      schedule: {
        schedule_campaign: 'Lên lịch chiến dịch',
        time_at: 'Thời gian',
        times: 'Số lần gửi',
        future_datetime: 'Vui lòng chọn ngày giờ tương lai',
        select_datetime: 'Vui lòng chọn ngày giờ',
        send_time: 'Gửi 1 lần',
      },
    },
  },
  translate_catalog: {
    city: ['Thành phố', 'Tỉnh'],
    district: ['Thành phố', 'Phường', 'Huyện', 'Thị xã', 'Quận'],
    ward: ['Thị trấn', 'Xã', 'Phường'],
    street: ['Đường', 'Phố', 'Tỉnh lộ', 'duong', 'Quốc Lộ', 'Đại Lộ'],
    demands: ['Cần bán', 'Cho thuê', 'Cần mua', 'Cần thuê'],
    categories: {
      apartment: {
        title: 'Căn hộ/Chung cư',
        apartment: 'Chung cư',
        duplex: 'Duplex',
        penthouse: 'Penthouse',
        serviced_apartment: 'Căn hộ dịch vụ, mini',
        housing_project: 'Tập thể, cư xá',
        officetel: 'Officetel',
      },
      house: {
        title: 'Nhà ở',
        town_house: 'Nhà mặt phố, mặt tiền',
        alley_house: 'Nhà ngõ, hẻm',
        villa: 'Nhà biệt thự',
        terrace_house: 'Nhà phố liền kề',
      },
      land: {
        title: 'Đất',
        industrial_land: 'Đất công nghiệp',
        agricultural_land: 'Đất nông nghiệp',
        residential_land: 'Đất thổ cư',
        ground_project: 'Đất nền dự án',
      },
      commercial: {
        title: 'Văn phòng, Mặt bằng kinh doanh',
        business_premises: 'Mặt bằng kinh doanh',
        office: 'Văn phòng',
        shophouse: 'Shophouse',
        officetel: 'Officetel',
      },
      rooming_house: {
        title: 'Phòng trọ',
        rooming_house: 'Phòng trọ',
      },
    },
  },
  today: 'Hôm nay',
  empty_data: 'Không có dữ liệu',
  version: 'Phiên bản',
  app: `ứng dụng`,
  user: `người dùng`,
  list: `danh sách`,
  edit: `chỉnh sửa`,
  shop: `cửa hàng`,
  blog: `blog`,
  post: `tin`,
  mail: `thư`,
  chat: `trò chuyện`,
  cards: `thẻ`,
  posts: `danh sách tin`,
  create: `tạo`,
  kanban: `bảng`,
  general: `chung`,
  banking: `ngân hàng`,
  booking: `đặt phòng`,
  profile: `hồ sơ`,
  account: `tải khoản`,
  product: `sản phẩm`,
  invoice: `hóa đơn`,
  details: `chi tiết`,
  checkout: `thanh toán`,
  calendar: `lịch`,
  analytics: `phân tích`,
  ecommerce: `thương mại điện tử`,
  management: `quản lý`,
  menu_level_1: `menu cấp 1`,
  menu_level_2a: `menu cấp 2a`,
  menu_level_2b: `menu cấp 2b`,
  menu_level_3a: `menu cấp 3a`,
  menu_level_3b: `menu cấp 3b`,
  menu_level_4a: `menu cấp 4a`,
  menu_level_4b: `menu cấp 4b`,
  item_disabled: `mục vô hiệu`,
  item_label: `mục nhãn`,
  item_caption: `mục chú thích`,
  item_external_link: `mục liên kết ngoài`,
  description: `mô tả`,
  other_cases: `Trường hợp khác`,
  item_by_roles: `mục theo vai trò`,
  only_admin_can_see_this_item: `Chỉ có quản trị viên mới có thể nhìn thấy mục này`,
};

export default vn;
