import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const BASE_ASSET_URL = 'api/v1/assets/';

const initialState = {
  isLoading: false,
  error: null,
  assets: [],
  calendarForm: {
    isOpen: false,
    asset: null,
  },
};

const slice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ASSETS
    getAssetsSuccess(state, action) {
      state.isLoading = false;
      state.assets = action.payload;
    },
    // CREATED ASSET SUCCCESS => AUTO OPEN CALENDAR FORM
    createdSuccess(state, action) {
      const calendarForm = {
        ...state.calendarForm,
        isOpen: true,
        asset: action.payload,
      };
      state.calendarForm = { ...calendarForm };
    },
    // CLEAR DATA CALENDAR FORM AFTER POPUP
    // eslint-disable-next-line no-unused-vars
    clearCalendarForm(state, action) {
      const calendarForm = {
        isOpen: false,
        asset: null,
      };
      state.calendarForm = { ...calendarForm };
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

async function getAssetByUrl(url, assets) {
  const response = await axios.get(url);
  if (response?.data) {
    const { results, next } = response.data;
    results.forEach((result) => {
      assets.push({
        id: result?.uuid,
        label: result?.title,
        images: result?.images || [],
        title: result?.title,
        createdAt: result?.created_at,
        city: result?.city_name,
        description: result?.description,
      });
    });
    if (next) {
      const page = next.split('?')[1];
      await getAssetByUrl(`${BASE_ASSET_URL}?${page}`, assets);
    }
  }
}

export function getAssets() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const assets = [];
      await getAssetByUrl(BASE_ASSET_URL, assets);
      dispatch(slice.actions.getAssetsSuccess(assets));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Get 10 assets latest
// eslint-disable-next-line consistent-return
export async function getLatestAssets(typeAsset, keyword = '') {
  try {
    const assets = [];
    const response = await axios.get(
      `${BASE_ASSET_URL}?ordering=-updated_at&type=${typeAsset}&filter[search]=${keyword}`
    );
    if (response?.data) {
      const { results } = response.data;
      results.forEach((result) => {
        assets.push({
          ...result,
          ...result.bds_details,
          ...result.mxh_details,
          ...result.pnt_details,
          ...result.web_details,
          id: result?.uuid,
          label: result?.title,
          images: result?.images || [],
          title: result?.title,
          createdAt: result?.created_at,
          city: result?.city_name,
          description: result?.description,
        });
      });
      return assets;
    }
  } catch (error) {
    console.log({ error });
  }
}

// Get assets by keyword
// eslint-disable-next-line consistent-return
export async function getAssetsByKeyword(keyword, typeAsset) {
  try {
    const assets = [];
    const response = await axios.get(`${BASE_ASSET_URL}?filter[search]=${keyword}&type=${typeAsset}`);
    if (response?.data) {
      const { results } = response.data;
      results.forEach((result) => {
        assets.push({
          ...result.bds_details,
          ...result.mxh_details,
          ...result.pnt_details,
          ...result.web_details,
          id: result?.uuid,
          label: result?.title,
          images: result?.images || [],
          title: result?.title,
          createdAt: result?.created_at,
          city: result?.city_name,
          description: result?.description,
          next: result?.next,
          previous: result?.previous,
          count: result?.count,
        });
      });
      return assets;
    }
  } catch (error) {
    console.log({ error });
  }
}

// Get assets by keyword
// eslint-disable-next-line consistent-return
export async function getAssetsWithPaginateByKeyword(keyword, typeAsset) {
  try {
    const assets = [];
    const response = await axios.get(`${BASE_ASSET_URL}?filter[search]=${keyword}&type=${typeAsset}`);
    if (response?.data) {
      const { results, next, previous, count } = response.data;
      results.forEach((result) => {
        assets.push({
          ...result,
          ...result.bds_details,
          ...result.mxh_details,
          ...result.pnt_details,
          ...result.web_details,
          id: result?.uuid,
          label: result?.title,
          images: result?.images || [],
          title: result?.title,
          createdAt: result?.created_at,
          city: result?.city_name,
          description: result?.description,
        });
      });
      return {
        assets,
        next,
        previous,
        count,
      };
    }
  } catch (error) {
    console.log({ error });
  }
}

// eslint-disable-next-line consistent-return
export async function getAssetsByUUID(uuid) {
  try {
    const response = await axios.get(`${BASE_ASSET_URL}${uuid}/`);
    if (response?.data) {
      const result = response.data;
      return {
        ...result,
        ...result.bds_details,
        ...result.mxh_details,
        ...result.pnt_details,
        ...result.web_details,
        id: result.uuid,
        city: result?.city?.name,
      };
    }
  } catch (error) {
    console.log({ error });
  }
}

export function popupCalendarForm(asset) {
  dispatch(slice.actions.createdSuccess(asset));
}

export function clearCalendarForm() {
  dispatch(slice.actions.clearCalendarForm());
}
