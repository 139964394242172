// ----------------------------------------------------------------------

const PRIMARY_NAME = ['A', 'N', 'H', 'L', 'Q', '9', '8'];
const INFO_NAME = ['F', 'G', 'T', 'I', 'J', '1', '2', '3'];
const SUCCESS_NAME = ['K', 'D', 'Y', 'B', 'O', '4', '5'];
const WARNING_NAME = ['P', 'E', 'R', 'S', 'C', 'U', '6', '7'];
const ERROR_NAME = ['V', 'W', 'X', 'M', 'Z'];

function getFirstCharacter(name) {
  return name && name.charAt(0).toUpperCase();
}

function getAvatarColor(name) {
  if (PRIMARY_NAME.indexOf(getFirstCharacter(name)) !== -1) return 'primary';
  if (INFO_NAME.indexOf(getFirstCharacter(name)) !== -1) return 'info';
  if (SUCCESS_NAME.indexOf(getFirstCharacter(name)) !== -1) return 'success';
  if (WARNING_NAME.indexOf(getFirstCharacter(name)) !== -1) return 'warning';
  if (ERROR_NAME.indexOf(getFirstCharacter(name)) !== -1) return 'warning';
  return 'default';
}

export default function createAvatar(name) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name),
  };
}

export async function URLtoFile(url) {
  const res = await fetch(url, {
    mode: 'no-cors',
  });
  const blob = await res.blob();

  const mime = blob.type;
  const ext = mime.slice(mime.lastIndexOf('/') + 1, mime.length);
  // Gets blob MIME type (e.g. image/png) and extracts extension
  return new File([blob], `filename.${ext}`, {
    type: mime,
  });
}

export async function compressFile(file, quality = 100) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      if (!img || !e) {
        // Failed to create Image object
        reject(new Error('Failed to create Image object.'));
      }
      img.src = e?.target?.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          // Failed to create canvas object
          reject(new Error('Failed to create canvas object.'));
        }

        // Set the desired width and height for the compressed image
        const maxWidth = 800;
        const maxHeight = 600;

        let newWidth = img.width;
        let newHeight = img.height;

        // Resize the image while maintaining its aspect ratio
        if (img.width > maxWidth) {
          newWidth = maxWidth;
          newHeight = (img.height * maxWidth) / img.width;
        }

        if (newHeight > maxHeight) {
          newWidth = (newWidth * maxHeight) / newHeight;
          newHeight = maxHeight;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        // Draw the resized image onto the canvas
        ctx?.drawImage(img, 0, 0, newWidth, newHeight);

        // Convert the canvas content back to a compressed image
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              // Failed to read blob object
              reject(new Error('Failed to read blob object.'));
            }

            const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });

            // Resolve with the compressed file and its size
            resolve(compressedFile);
          },
          'image/jpeg',
          quality
        );
      };
    };

    reader.onerror = () => {
      reject(new Error('Failed to read the file.'));
    };

    reader.readAsDataURL(file);
  });
}

export function detectNudeList(nodeIds) {
  return new Promise((resolve) => {
    const results = [];

    if (nodeIds?.length !== 0) {
      (function testImage(i) {
        const nodeId = nodeIds[i];

        window?.nude?.load(nodeId);
        window?.nude?.scan((result) => {
          results.push(result);
          if (i !== nodeIds.length - 1) {
            testImage(i + 1);
          } else {
            resolve(results);
          }
        });
      })(0);
    } else {
      resolve([]);
    }
  });
}

/**
 *
 * @param {string} nodeId
 * @returns Boolean
 *
 */
export function detectNude(nodeId) {
  return new Promise((resolve) => {
    if (window?.nude && nodeId) {
      const ele = document.getElementById(nodeId);
      if (ele) {
        window?.nude?.load(ele);
        window?.nude?.scan((result) => {
          resolve(result);
        });
      }
    }
  });
}
