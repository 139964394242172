import { useState } from 'react';
/**
 * @param onTrue - function set value to true
 * @param onFalse - function set value to false
 * @param onToggle - Set inverse current value
 * @param value - Current value
 */
const useBoolean = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const onTrue = () => {
    setValue(true);
  };

  const onFalse = () => {
    setValue(false);
  };

  const onToggle = () => {
    setValue((prev) => !prev);
  };

  return {
    onTrue,
    onFalse,
    onToggle,
    value,
  };
};
export default useBoolean;
