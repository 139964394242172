import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const BASE_SITE_ACCOUNTS_URL = 'api/v1/accounts/';
const BASE_SITES_URL = 'api/v5/commons/sites/';

const initialState = {
  isLoading: false,
  error: null,
  sites: [],
  siteAccounts: [],
  siteAdPackages: [],
};

const slice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SITES
    getSitesSuccess(state, action) {
      state.isLoading = false;
      state.sites = action.payload;
    },

    // GET SITE ACCOUNTS
    getSiteAccountsSuccess(state, action) {
      state.isLoading = false;
      state.siteAccounts = action.payload;
    },

    // GET SITE AD PACKAGES
    getSiteAdPackagesSuccess(state, action) {
      state.isLoading = false;
      state.siteAdPackages = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSiteAccounts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let siteAccounts = [];
      const response = await axios.get(BASE_SITE_ACCOUNTS_URL);
      if (!isEmpty(response?.data)) {
        siteAccounts = response.data.map((siteAccount) => ({
          ...siteAccount,
          id: siteAccount.id,
          label: siteAccount?.site_identity_name,
        }));
      }
      dispatch(slice.actions.getSiteAccountsSuccess(siteAccounts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSites() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let sites = [];
      const siteAdPackages = [];
      const response = await axios.get(BASE_SITES_URL);
      if (response?.data?.count > 0) {
        sites = response.data.results.map((site) => {
          const siteAdPackage = site?.site_ad_package || [];
          if (!isEmpty(siteAdPackage)) {
            siteAdPackage.forEach((siteAd) => {
              if (siteAd.is_active) {
                siteAdPackages.push({
                  ...siteAd,
                  id: siteAd.id,
                  label: siteAd.name,
                  site: siteAd.site_identity,
                });
              }
            });
          }
          return {
            ...site,
            id: site.id,
            label: site.alias,
          };
        });
      }
      dispatch(slice.actions.getSitesSuccess(sites));
      dispatch(slice.actions.getSiteAdPackagesSuccess(siteAdPackages));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
