import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { PullDownContent, PullToRefresh, RefreshContent, ReleaseContent } from 'react-js-pull-to-refresh';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import PopupRealtime from '../../components/PopupRealtime';
// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useResponsive from '../../hooks/useResponsive';
import useSettings from '../../hooks/useSettings';
// redux
import { useSelector } from '../../redux/store';

// config
import { HEADER, NAVBAR } from '../../config';
//
import useLocales from '../../hooks/useLocales';
import DashboardHeader from './header';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import NavbarVertical from './navbar/NavbarVertical';

// ----------------------------------------------------------------------
const NOTY_POPUP_TYPE = 'popup_to_client';

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

const SOCIAL_MAP = {
  facebook: 'facebook',
  linkedin: 'linkedin-oauth2',
};

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { translate } = useLocales();

  const { themeLayout } = useSettings();

  const { user } = useAuth();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const { anotherNotiType } = useSelector((state) => state.noti);

  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const [popupContent, setPopupContent] = useState({
    message: '',
    variant: '',
    social: '',
  });

  useEffect(() => {
    if (user) {
      // Check info user required here!!!!
      const { firstname, lastname, phone } = user;
      const phoneRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
      // Validate
      if ((!firstname || !lastname || !phone || !phoneRegex.test(phone)) && pathname !== '/dashboard/user/account') {
        enqueueSnackbar(translate('complete_infomation'), { variant: 'warning' });
        navigate('/dashboard/user/account');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, pathname]);

  useEffect(() => {
    if (!isEmpty(anotherNotiType) && anotherNotiType?.type === NOTY_POPUP_TYPE) {
      setPopupContent({
        message: anotherNotiType.message,
        variant: anotherNotiType.variant,
        social: anotherNotiType.social,
      });
      setIsOpenPopup(true);
    }
  }, [anotherNotiType]);

  const handleClosePopup = () => {
    setIsOpenPopup(false);
  };

  const handleLinkingAccount = () => {
    if (popupContent?.social) {
      const urlRedirect = `${process.env.REACT_APP_HOST_API_KEY}login/${
        SOCIAL_MAP[popupContent?.social]
      }/?linking=1&email=${user.email}`;
      localStorage.setItem('isLinking', urlRedirect);
      window.location.href = urlRedirect;
    }
  };

  const onRefresh = () =>
    new Promise(() => {
      setTimeout(window.location.reload(), 2000);
    });

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          {isDesktop ? (
            <Outlet />
          ) : (
            <PullToRefresh
              pullDownContent={<PullDownContent label="Kéo để refresh" />}
              releaseContent={<ReleaseContent label="Thả để refresh" />}
              refreshContent={<RefreshContent />}
              pullDownThreshold={100}
              onRefresh={onRefresh}
              triggerHeight="auto"
              startInvisible
            >
              <Outlet />
            </PullToRefresh>
          )}
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        {isDesktop ? (
          <Outlet />
        ) : (
          <PullToRefresh
            pullDownContent={<PullDownContent label="Kéo để refresh" />}
            releaseContent={<ReleaseContent label="Thả để refresh" />}
            refreshContent={<RefreshContent />}
            pullDownThreshold={100}
            onRefresh={onRefresh}
            triggerHeight="auto"
            startInvisible
          >
            <Outlet />
          </PullToRefresh>
        )}
      </MainStyle>
      <PopupRealtime
        isOpen={isOpenPopup}
        handleClose={handleClosePopup}
        popupContent={popupContent}
        handleLinkingAccount={handleLinkingAccount}
      />
    </Box>
  );
}
