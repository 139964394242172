import OneSignal from 'react-onesignal';

export default async function runOneSignal() {
  // get user setting here!
  await OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_APP_ID || '274877a6-f6e4-40d4-a263-e9f33dc399cd',
    safari_web_id:
      process.env.REACT_APP_ONESIGNAL_SAFARI_APP_ID || 'web.onesignal.auto.4463433a-b41c-4a34-809b-879a9d93883b',
    serviceWorkerParam: { scope: '/push/onesignal/' },
    serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
    allowLocalhostAsSecureOrigin: true,
    promptOptions: {
      slidedown: {
        enabled: true,
        actionMessage: 'Nhấn đồng ý để nhận những thông báo mới nhất từ chúng tôi!',
        acceptButtonText: 'Đồng ý',
        cancelButtonText: 'Từ chối',
        categories: {
          tags: [
            {
              tag: 'blog',
              label: 'Các bài viết blog mới',
            },
            {
              tag: 'update',
              label: 'Cập nhật, và thông báo hệ thống',
            },
          ],
        },
      },
    },
    welcomeNotification: {
      title: 'Uptin.vn',
      message: 'Bạn sẽ nhận được những thông báo mới nhất!',
    },
  });
}
