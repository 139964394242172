import { PropTypes } from 'prop-types';
import { memo } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

FeedKeywordIcon.propTypes = {
  color: PropTypes.string,
};

function FeedKeywordIcon({ color, ...other }) {
  return (
    <Box {...other}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={color || '#000000'} stroke="none">
          <path
            d="M1885 5114 c-436 -50 -757 -174 -1080 -417 -103 -78 -304 -279 -382
-382 -413 -549 -532 -1247 -318 -1880 107 -317 263 -572 493 -803 317 -319
725 -524 1167 -588 223 -32 527 -17 758 37 267 63 568 207 776 373 l68 54 734
-734 c490 -491 747 -741 775 -754 70 -34 142 -21 196 36 52 56 61 120 28 188
-13 28 -263 285 -754 775 l-734 734 54 68 c166 208 310 509 373 776 54 232 69
538 36 758 -67 450 -265 844 -585 1164 -335 336 -774 544 -1244 590 -102 10
-291 13 -361 5z m436 -359 c588 -87 1122 -528 1327 -1095 114 -315 135 -679
56 -995 -154 -617 -632 -1095 -1249 -1249 -257 -64 -563 -62 -830 5 -649 163
-1156 719 -1261 1383 -21 132 -22 409 -1 531 63 374 219 678 482 940 298 299
661 464 1095 499 75 6 279 -4 381 -19z"
          />
          <path
            d="M812 3917 c-53 -10 -107 -61 -121 -114 -15 -52 -15 -1404 -1 -1455
30 -108 149 -160 244 -105 80 45 81 48 84 322 l3 244 45 37 c24 20 47 35 49
32 3 -2 63 -130 135 -283 72 -153 140 -291 151 -307 43 -59 133 -84 202 -54
65 27 116 120 101 183 -4 15 -74 171 -155 345 -82 175 -149 321 -149 327 0 5
58 52 130 106 163 122 193 168 169 258 -12 45 -48 90 -91 113 -32 17 -111 18
-141 3 -12 -6 -115 -81 -228 -165 -113 -85 -209 -154 -212 -154 -4 0 -7 125
-7 279 0 272 0 279 -22 311 -46 67 -109 93 -186 77z"
          />
          <path
            d="M1960 3558 c-52 -36 -73 -71 -77 -130 -4 -47 15 -109 171 -577 96
-288 186 -539 198 -558 32 -47 97 -77 154 -70 86 9 105 32 217 254 56 112 104
203 107 203 3 0 48 -86 100 -190 95 -190 129 -239 183 -259 68 -26 162 4 197
61 11 18 99 269 195 558 125 376 175 538 175 571 0 54 -28 102 -79 136 -69 46
-188 19 -230 -52 -10 -16 -65 -169 -121 -340 -56 -170 -105 -313 -109 -317 -3
-4 -45 70 -93 165 -78 155 -91 175 -126 195 -54 30 -130 30 -184 0 -35 -20
-48 -41 -128 -198 -54 -107 -91 -169 -94 -160 -3 8 -53 156 -110 327 -57 172
-114 326 -125 342 -44 62 -157 82 -221 39z"
          />
        </g>
      </svg>
    </Box>
  );
}

export default memo(FeedKeywordIcon);
