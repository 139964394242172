import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  tasks: [],
  pendingTasks: [],
};

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LIST SUCCESS
    getListPendingSuccess(state, action) {
      // For reload window
      const allPendingsTasksFromBE = action.payload;
      let temp = [...state.pendingTasks, ...allPendingsTasksFromBE];
      temp = uniqBy(temp, 'request_code');
      state.pendingTasks = [...temp];
    },

    // ADD PENDING TASK
    addNewTask(state, action) {
      const { task } = action.payload;
      const temp = uniqBy([...state.pendingTasks, task], 'request_code');
      // eslint-disable-next-line no-unused-vars
      state.pendingTasks = [...temp];
      // fakeWebsocket(true, task,'clone');
    },

    markAsCompleted(state, action) {
      const { task } = action.payload;
      const temp = [...state.pendingTasks];
      const index = temp.findIndex((item) => item?.request_code === task?.request_code);
      if (index !== -1) {
        // Remove
        temp?.splice(index, 1);
        state.pendingTasks = [...temp];
        state.tasks = uniqBy([...state.tasks, task], 'request_code');
      }
    },

    markAsRead(state, action) {
      const { requestCode } = action.payload;
      const temp = [...state.tasks];
      const index = temp.findIndex((item) => item?.request_code === requestCode);
      if (index !== -1) {
        // Remove after user have seen
        temp?.splice(index, 1);
        state.tasks = uniqBy([...temp], 'request_code');
      }
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export async function addNewPendingTask(task) {
  dispatch(slice.actions.addNewTask({ task }));
}

export async function markAsCompleted(task) {
  dispatch(slice.actions.markAsCompleted({ task }));
}

export async function markAsRead(requestCode) {
  dispatch(slice.actions.markAsRead({ requestCode }));
}

export async function getCurentPendingTasks(url, params) {
  const response = await axios.get(url, { params });
  if (response?.data) {
    const { results, next } = response.data;
    dispatch(slice.actions.getListPendingSuccess(results));

    if (next && undefined === params.page) {
      // first call
      const { count } = response.data;
      const pageSize = results.length;
      const concurrency = Math.ceil(count / pageSize);
      for (let page = 2; page <= concurrency; page += 1) {
        (async () => {
          params.page = page;
          await getCurentPendingTasks(url, params);
        })();
      }
    }
  }
}

// function fakeWebsocket(success,data,type='generate') {
//   return new Promise((resolve,reject)=>{
//     const fakeData = {
//       ...data,
//       type: 'noty_ai_task_completed',
//       status: 'completed',
//       result_data: {
//         name: 'gpt-1526-2868-spin',
//         action: 'generate',
//         success,
//         asset_type: 'MXH',
//         asset_uuid: 'acb34c8c-c48d-4170-8dfd-35d99f0f032e',
//         input_name: 'description',
//         new_content: success
//           ? 'Thành công ....'
//           : 'Có lỗi ....',
//         action_description: 'Sử dụng AI thay đổi nội dung cho tin "Mua ngay căn hộ chung cư kháng cách tại thành công',
//       },
//       response_time: 57110,
//     };
//     setTimeout(() => {
//       markAsCompleted(fakeData);
//       resolve();
//     }, 4000);
//   })
// }
