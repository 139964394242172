import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { WEBSOCKET_TIMEOUT } from '../config';
// hooks
import useAuth from '../hooks/useAuth';
import useIdleTimer from '../hooks/useIdleTimer';
import { updateTimeReNew } from '../redux/slices/dataExpiration';
import axios from '../utils/axios';

const initialState = {
  wsClient: null,
  // Reset websocket instance
  advanceLogout: () => {},
};

const WebSocketContext = createContext(initialState);

// Time check user idle (seconds) -> default: 5 minutes equal time expire of websocket key from BE
const idleTime = WEBSOCKET_TIMEOUT * 60;

// ---------------------- PROPS VALIDATE ---------------------
WebSocketProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function WebSocketProvider({ children }) {
  const { user, configs, logout } = useAuth();
  const { isIdle } = useIdleTimer({ idleTime });

  const [wsClient, setWebSocketClient] = useState(null);

  const firstConnectTime = useRef(null);

  // const pingRef = useRef(null);

  const tryConnectWebsocket = useCallback(
    (websocketKey = null) => {
      try {
        if (isIdle) {
          if (wsClient) {
            console.log('User idled');
            wsClient.close();
          }
        } else if (!wsClient && user && websocketKey && !firstConnectTime.current) {
          // AWS API Gateway
          const temp = new W3CWebSocket(
            `${configs?.aws_websocket_endpoint?.replace('https', 'wss')}?websocket_key=${websocketKey}`
          );
          temp.onopen = () => {
            console.log('WebSocket Client Connected at %o', Date());
            if (!firstConnectTime.current) {
              firstConnectTime.current = Date.now();
            }
          };

          temp.onclose = () => {
            console.log('Server have closed at %o, Waitting for user online!', Date());
            // Storage time when user disconnect with server
            setWebSocketClient(null);
          };
          setWebSocketClient(temp);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isIdle, user]
  );

  useEffect(() => {
    if (user) {
      tryConnectWebsocket(user?.websocket_key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, user]);

  useEffect(() => {
    (async () => {
      if (!isIdle && user && !wsClient && firstConnectTime.current) {
        const diff = Date.now() - firstConnectTime.current;
        const defaultWebsocketKeyTime = idleTime * 1000;
        if (diff >= defaultWebsocketKeyTime) {
          // Enhance here so what we do?
          /**
           * 1. Don't reload page get new websocket -> Add new api for BE to generate websocket token(websocket token have timelife current 5 minutes)
           * 2. All components have subscribed websocket should ve up to date when user reconnect again
           * Flow descriptions:
           * - Create new redux slice
           * - When user access any route. Web will update path into slice(Benefit is if user have online on this route, we only need update data in that component)
           * - Layouts related to websocket must be up to date.
           */
          console.log('start fecth new data ...');
          updateTimeReNew(Date.now());
          firstConnectTime.current = null;
          const user = await axios.get('api/v1/users/me/');
          const { websocket_key: websocketKey } = user?.data;
          tryConnectWebsocket(websocketKey);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, user, wsClient]);

  // Ping timer
  // useEffect(() => {
  //   if (!isIdle && user && wsClient) {
  //     pingRef.current = setInterval(()=>{
  //       wsClient.send({ action: 'heartbeat', message: 'ping' });
  //       console.log('ws')
  //     },PING_TIMER)
  //   }else{
  //     clearInterval(pingRef.current);
  //   }
  // }, [isIdle, user, wsClient]);

  const advanceLogout = async () => {
    // use this function if logout user
    try {
      await logout();
      firstConnectTime.current = null;
      wsClient?.close();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <WebSocketContext.Provider
      value={useMemo(
        () => ({
          wsClient,
          advanceLogout,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wsClient]
      )}
    >
      {children}
    </WebSocketContext.Provider>
  );
}

export { WebSocketProvider, WebSocketContext };
