import { format, getTime, formatDistanceToNow, startOfWeek, endOfWeek } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, seperator = '/') {
  if (!isValidDate(date)) {
    return '';
  }
  return format(new Date(date), `dd${seperator}MM${seperator}yyyy`);
}

export function fTime(date, timeFormat = 'HH:mm:ss') {
  if (!isValidDate(date)) {
    return '';
  }
  return format(new Date(date), timeFormat);
}

export function fDateTime(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

function isValidDate(date) {
  return date instanceof Date && !Number.isNaN(date);
}

export const convertDateString = (dateString, timeString) => {
  if (typeof dateString === 'string') {
    const [year, month, date] = dateString.split('/').reverse();
    const [hour, minute, second] = timeString.split(':');

    return new Date(year, month - 1, date, hour, minute, second);
  }
  return false;
};

export const getStartEndOfWeek = (date) => ({ start: startOfWeek(date), end: endOfWeek(date) });

export const isFutureDateTime = (date, time) => {
  const cloneDate = new Date(date.getTime());
  const currentDate = new Date();
  // Compare date
  cloneDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);
  const diff = cloneDate.getTime() - currentDate.getTime();
  if (diff >= 0) {
    // Compare by time
    const cloneTime = new Date(time.getTime());

    // Same day
    if (diff === 0) {
      return fTime(new Date(), 'HH:mm') < fTime(cloneTime, 'HH:mm');
    }

    return true;
  }
  return false;
};

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}
