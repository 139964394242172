import { Navigate } from 'react-router-dom';

// Disable path depend on deactivateRoutes(B2B Integrate)
export const routesToPath = (routes, deactivateRoutes = [], parentPath = '') => {
  /**
   * Auto replace route when route match with deactivate_routes of tenant -> permission denied
   */
  if (!deactivateRoutes) return routes;
  return routes.map((route) => {
    const { path, children } = route;
    const currentPath = `${parentPath}/${path}`;
    // Have children path in parent path?
    if (children) {
      route.children = routesToPath(children, deactivateRoutes, currentPath);
      // Is deactivateRoutes appear in current path?
    } else if (currentPath && stringAppearInArray(currentPath, deactivateRoutes)) {
      route.element = <Navigate to={'/dashboard/permission-denied'} replace />;
    }
    return route;
  });
};

const stringAppearInArray = (text, arr, separator = '/', containChar = ':', replaceWith = '@') => {
  // Split with separator -> check contain character -> replace it with replaceWith
  const newText = text
    .split(separator)
    .map((item) => {
      if (item.indexOf(containChar) !== -1) {
        return replaceWith;
      }
      return item;
    })
    .join('/');

  return arr.some((item) => {
    const txtLength = item.length;
    return item === newText.slice(-txtLength);
  });
};

// Is feature disabled
export const isDisabledFeature = (feature, arrDisable = []) => {
  /**
   * Check feature have been disabled by tenant. Depend on tenant config deactivate_features
   */
  if (feature) {
    return arrDisable.indexOf(feature) !== -1;
  }
  return false;
};
