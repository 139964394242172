import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

const initialState = {
  expiredAt: Date.now(),
};

const slice = createSlice({
  name: 'dataExpiration',
  initialState,
  reducers: {
    updateTimeReNew(state, action) {
      const { time } = action.payload;
      state.expiredAt = time;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export async function updateTimeReNew(time) {
  dispatch(slice.actions.updateTimeReNew({ time }));
}
