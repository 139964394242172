import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  tenants: [],
  currentTenant: null,
};

const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TENANTS
    getTenantsSuccess(state, action) {
      state.isLoading = false;
      state.tenants = uniqBy([...state.tenants, ...action.payload], 'id');
    },

    // UPDATE CURRENT TENANT
    updateCurrentTenant(state, action) {
      state.isLoading = false;
      state.currentTenant = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export async function getTenants(url, params) {
  const response = await axios.get(url, { params });
  if (response?.data) {
    const { results, next } = response.data;
    dispatch(slice.actions.getTenantsSuccess(results));

    if (next && undefined === params.page) {
      // first call
      const { count } = response.data;
      const pageSize = results.length;
      const concurrency = Math.ceil(count / pageSize);
      for (let page = 2; page <= concurrency; page += 1) {
        (async () => {
          params.page = page;
          await getTenants(url, params);
        })();
      }
    }
  }
}
export async function updateCurrentTenant(tenant) {
  if (tenant) {
    dispatch(slice.actions.updateCurrentTenant(tenant));
  }
}
