import _ from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { addNewNoty } from '../../../redux/slices/notification';
import { getSites } from '../../../redux/slices/site';
import { useDispatch, useSelector } from '../../../redux/store';
import { IconButtonAnimate } from '../../../components/animate';
import Label from '../../../components/Label';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    label: 'Trang',
    align: 'left',
  },

  {
    label: 'Tình trạng',
    align: 'left',
  },

  {
    label: 'Tương thích',
    align: 'left',
  },
];

const NOTY_SITE_STATUS_TYPE = 'noty_to_checker';
const NOTY_CLIENT_TYPE = 'noty_to_client';

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  const theme = useTheme();
  const [open, setOpen] = useState(null);
  const dipatch = useDispatch();
  const [_sites, _setSites] = useState([]);
  const { sites } = useSelector((state) => state.site);
  const { anotherNotiType } = useSelector((state) => state.noti);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    dipatch(getSites());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(anotherNotiType) && anotherNotiType.type === NOTY_SITE_STATUS_TYPE) {
      const { compatible, is_accessible: isAccessible, is_active: isActive } = _.get(anotherNotiType, 'data', '');
      _setSites((prevState) => {
        let temp = [];
        if (_.isEmpty(prevState)) {
          temp = [...sites];
        }
        temp = temp.map((site) =>
          site.id === +_.get(anotherNotiType, 'target_id', '')
            ? { ...site, is_active: isActive, is_accessible: isAccessible, compatible }
            : site
        );
        return temp;
      });
      // Update notification on redux
      const newNoty = { ...anotherNotiType, type: NOTY_CLIENT_TYPE };
      dipatch(addNewNoty(newNoty));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anotherNotiType]);

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'mingcute:earth-line'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 'inherit',
          height: '50%',
          overflowY: 'auto',
          '& .MuiMenuItem-root': {
            px: 1.5,
            borderRadius: 0.75,
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Số trang liên kết <Typography component="span">({sites.length})</Typography>
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((column, index) => (
                <TableCell style={{ backgroundColor: 'white' }} key={index} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {_.sortBy(_.isEmpty(_sites) ? [...sites] : [..._sites], (e) => e.alias).map((site) => (
              <TableRow key={site.id}>
                <TableCell>{site.alias}</TableCell>

                <TableCell>
                  <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={identifySiteStatus(site.is_active, site.is_accessible, site.compatible).color}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {identifySiteStatus(site.is_active, site.is_accessible, site.compatible).label}
                  </Label>
                </TableCell>

                <TableCell>
                  <span
                    style={{
                      color: identifySiteStatus(site.is_active, site.is_accessible, site.compatible).compabilityColor,
                    }}
                  >{`${site.compatible.toString()}%`}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </MenuPopover>
    </>
  );
}

const identifySiteStatus = (isActive, isAccessible, compabilityPercent = 0) => {
  let result = {};
  if (!isActive) {
    result = {
      label: 'Tạm ngưng',
      color: 'error',
      compabilityColor: '',
    };
  } else if (isActive && isAccessible) {
    let compabilityColor = '';
    if (compabilityPercent <= 30) {
      compabilityColor = '#B72136';
    } else if (compabilityPercent > 30 && compabilityPercent < 90) {
      compabilityColor = '#B78103';
    } else if (compabilityPercent >= 90) {
      compabilityColor = '#229A16';
    }
    result = {
      label: 'Đang hoạt động',
      color: 'success',
      compabilityColor,
    };
  } else if (isAccessible && !isAccessible) {
    result = {
      label: 'Đang sửa lỗi',
      color: 'warning',
      compabilityColor: '',
    };
  }
  return result;
};
