import { memo } from 'react';
import { PropTypes } from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

function LogoUptin({ color, ...other }) {
  const theme = useTheme();

  const PRIMARY_MAIN = color || theme.palette.primary.main;

  return (
    <Box {...other}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 674.000000 674.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,674.000000) scale(0.100000,-0.100000)" fill={PRIMARY_MAIN} stroke="none">
          <path
            d="M4110 5563 c-297 -30 -517 -100 -746 -236 -76 -45 -367 -215 -646
-377 -280 -162 -508 -297 -508 -300 0 -6 2300 -1330 2335 -1344 19 -8 77 23
384 204 312 184 359 214 344 225 -10 6 -111 65 -225 130 -115 66 -208 122
-208 125 0 3 42 28 92 55 381 207 628 428 718 640 22 52 25 75 25 171 0 107
-1 112 -37 186 -87 175 -306 348 -545 429 -217 74 -401 99 -713 97 -129 -1
-251 -3 -270 -5z m312 -577 c95 -19 154 -40 196 -72 103 -79 95 -185 -21 -290
-46 -41 -375 -243 -412 -252 -13 -3 -578 323 -583 336 -4 13 455 261 511 277
68 18 217 19 309 1z"
          />
          <path
            d="M1848 3777 c-197 -116 -358 -214 -358 -217 0 -3 499 -293 1110 -645
l1110 -640 0 -317 c0 -175 -2 -318 -4 -318 -2 0 -498 284 -1102 631 -909 522
-1102 630 -1120 623 -34 -14 -714 -408 -714 -414 0 -6 2200 -1280 2234 -1294
18 -7 144 63 731 407 l710 414 3 349 2 349 -1106 640 c-608 352 -1113 641
-1122 642 -9 1 -178 -93 -374 -210z"
          />
          <path
            d="M5118 3467 l-88 -52 0 -108 0 -108 23 12 c12 6 52 30 90 52 l67 39 0
109 c0 60 -1 109 -2 108 -2 0 -42 -24 -90 -52z"
          />
          <path
            d="M4753 3253 l-93 -54 0 -109 0 -110 88 51 c48 28 93 58 100 67 13 17
18 197 5 205 -5 3 -50 -20 -100 -50z"
          />
          <path
            d="M5118 3097 l-88 -51 0 -104 0 -104 38 22 c20 12 61 36 90 52 l52 30
0 104 c0 57 -1 104 -2 104 -2 -1 -42 -24 -90 -53z"
          />
          <path
            d="M4753 2884 l-93 -55 0 -99 c0 -55 3 -100 8 -100 4 0 48 24 97 53 l90
53 3 102 c1 56 -1 102 -5 102 -4 -1 -50 -25 -100 -56z"
          />
          <path
            d="M5118 2738 l-88 -51 0 -104 0 -104 43 26 c23 14 63 37 90 52 l47 26
0 104 c0 56 -1 103 -2 103 -2 0 -42 -24 -90 -52z"
          />
          <path
            d="M4758 2528 l-88 -53 0 -103 c0 -57 3 -101 8 -99 4 2 46 27 95 56 l87
52 0 100 c0 54 -3 99 -7 99 -5 0 -47 -24 -95 -52z"
          />
        </g>
      </svg>
    </Box>
  );
}

LogoUptin.propTypes = {
  color: PropTypes.string,
};

export default memo(LogoUptin);
