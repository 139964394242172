import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
// utils
import axios from '../../utils/axios';
import { fDate, getStartEndOfWeek } from '../../utils/formatTime';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const BASE_EVENT_GROUP_URL = 'api/v1/scheduled/group/';
const HISTORY_EVENT_GROUP_URL = 'api/v1/scheduled/group/history/';
const DETAIL_SCHEDULED_URL = 'api/v1/scheduled/detail/';
const initialState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  allEvents: [],
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      const oldEvents = [...state.events];
      // Remove dulicate
      const concatedArray = action.payload.concat(oldEvents);

      const list = uniqBy(concatedArray, 'compareKey');

      // Events have group property
      state.events = [...list];
    },
    // UPDATE LIST EVENTS INFO
    loadAllEventsInfo(state, action) {
      state.isLoading = false;
      const oldEvents = [...state.allEvents];
      // Remove dulicate
      const concatedArray = action.payload.concat(oldEvents);
      const list = uniqBy(concatedArray, 'id');

      // List all events
      state.allEvents = [...list];
    },
    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      //     event id, asset_uuid, time,
      const { eventId, date, asset_uuid: assetUuid } = action.payload;
      const compareKey = `${assetUuid}${date}`;

      const temp = [...state.events];

      // isExist
      const index = temp.findIndex((item) => item.compareKey === compareKey);
      if (index !== -1) {
        const pos = temp[index].scheduled_ids.findIndex((id) => id === eventId);
        if (pos !== -1) {
          if (temp[index].scheduled_ids.length === 1) {
            temp.splice(index, 1);
          } else {
            temp[index].scheduled_ids.splice(pos, 1);
            temp[index].status_ids.splice(pos, 1);
            temp[index].site_ids.splice(pos, 1);
            temp[index].times.splice(pos, 1);
            temp[index].group_page_ids.splice(pos, 1);
          }
        }
      }
      state.isLoading = false;
      state.events = [...temp];
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

const scheduleColorMap = {
  1: '#FFC107',
  2: '#FF4842',
  3: '#00AB55',
};

async function getEventsGroupByUrl(url, params) {
  const response = await axios.get(url, { params });
  if (response?.data) {
    const { results, next } = response.data;
    const newEvents = [];
    results
      .filter((res) => res.scheduled_post_status !== 4)
      .forEach((asset) => {
        const startDate = new Date(asset.datetime);
        const dateString = fDate(startDate, '-').split('-').reverse().join('-');
        newEvents.push({
          compareKey: `${asset.asset_uuid}${dateString}`,
          id: asset.asset_uuid,
          uuid: asset.asset_uuid,
          start: dateString,
          end: dateString,
          title: asset.asset_title,
          time: new Date(asset.datetime),
          scheduled_ids: asset.scheduled_ids,
          status_ids: asset.status_ids,
          site_ids: asset.site_ids,
          times: asset.times,
          type: asset.asset_type,
          group_page_ids: asset.group_page_ids,
        });
      });
    dispatch(slice.actions.getEventsSuccess(newEvents));

    if (next && undefined === params.page) {
      // first call
      const { count } = response.data;
      const pageSize = results.length;
      const concurrency = Math.ceil(count / pageSize);
      for (let page = 2; page <= concurrency; page += 1) {
        (async () => {
          params.page = page;
          await getEventsGroupByUrl(url, params);
        })();
      }
    }
  }
}

export async function loadEventById(id) {
  // Get detail info of each event
  try {
    const response = await axios.get(`${DETAIL_SCHEDULED_URL}${id}/`);
    let results = response.data;
    results = [results].map((asset) => {
      const startDate = new Date(asset.datetime);
      const dateString = fDate(startDate, '-').split('-').reverse().join('-');
      return {
        id: asset.id,
        note: asset.note,
        uuid: asset.asset_uuid,
        start: dateString,
        end: dateString,
        title: asset.title,
        description: asset.note,
        site: asset.site_identity,
        time: new Date(asset.datetime),
        postStatus: asset.scheduled_post_status,
        textColor: scheduleColorMap[asset.scheduled_post_status],
        meta: results.meta,
        siteAdLink: asset.site_ad_link,
        siteAdPackageId: results?.site_ad_package,
        siteAccountId: results?.site_account,
      };
    });
    dispatch(slice.actions.loadAllEventsInfo(results));
  } catch (error) {
    console.log(error);
  }
}

// Load each event info to redux
export async function loadEventByIds(url, eventIds, params) {
  // Get detail info of each event
  const response = await axios.get(url, { params: { ...params, scheduled_ids: [...eventIds].join(',') } });
  const { next, results } = response.data;
  const newEvents = [];
  results
    .filter((res) => res.scheduled_post_status !== 4)
    .forEach((asset) => {
      const startDate = new Date(asset.datetime);
      const dateString = fDate(startDate, '-').split('-').reverse().join('-');
      newEvents.push({
        id: asset.id,
        note: asset.note,
        uuid: asset.asset_uuid,
        start: dateString,
        end: dateString,
        title: asset.title,
        description: asset.note,
        site: asset.site_identity,
        time: new Date(asset.datetime),
        postStatus: asset.scheduled_post_status,
        textColor: scheduleColorMap[asset.scheduled_post_status],
      });
    });
  dispatch(slice.actions.loadAllEventsInfo(newEvents));

  if (next && undefined === params.page) {
    // first call
    const { count } = response.data;
    const pageSize = results.length;
    const concurrency = Math.ceil(count / pageSize);
    for (let page = 2; page <= concurrency; page += 1) {
      (async () => {
        params.page = page;
        await loadEventByIds(url, eventIds, params);
      })();
    }
  }
}

export function getEvents(date, view) {
  const dateString = date;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      // console.log('current', currentDate);
      // check if history previous month
      const split = dateString.split('/');
      const day = Number(split[0]);
      const month = Number(split[1]);
      const year = Number(split[2]);
      const chosenDate = new Date(year, month - 1, day);
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      let firstDay = new Date(currentYear, currentMonth, 1);
      let lastDay = new Date(currentYear, currentMonth + 1, 0);
      // --------------- For week display --------------------
      if (view === 'listWeek') {
        const { start: firstDayOfWeek, end: endDayOfWeek } = getStartEndOfWeek(chosenDate);
        if (currentDate.getTime() > chosenDate.getTime() && currentDate.getTime() > endDayOfWeek.getTime()) {
          // past events
          await getEventsGroupByUrl(HISTORY_EVENT_GROUP_URL, {
            dateStart: fDate(firstDayOfWeek),
            dateEnd: fDate(endDayOfWeek),
          });
        } else if (
          month === currentMonth + 1 &&
          year === currentYear &&
          currentDate.getTime() > firstDayOfWeek.getTime() &&
          currentDate.getTime() < endDayOfWeek.getTime()
        ) {
          // get weekly historical data and future data
          // this month: previous
          const yesterday = new Date(currentYear, currentMonth, currentDate.getDate() - 1);
          await getEventsGroupByUrl(HISTORY_EVENT_GROUP_URL, {
            dateStart: fDate(firstDayOfWeek),
            dateEnd: fDate(yesterday),
          });
          // this month: future
          await getEventsGroupByUrl(BASE_EVENT_GROUP_URL, { dateStart: dateString, dateEnd: fDate(endDayOfWeek) });
        } else {
          // future events
          await getEventsGroupByUrl(BASE_EVENT_GROUP_URL, {
            dateStart: fDate(firstDayOfWeek),
            dateEnd: fDate(endDayOfWeek),
          });
        }
        return;
      }
      // --------------- For month display --------------------
      if (currentDate.getTime() > chosenDate.getTime() && month !== currentMonth + 1) {
        // past events
        firstDay = new Date(year, month - 1, 1);
        lastDay = new Date(year, month, 0);
        await getEventsGroupByUrl(HISTORY_EVENT_GROUP_URL, { dateStart: fDate(firstDay), dateEnd: fDate(lastDay) });
      } else if (month === currentMonth + 1 && year === currentYear) {
        // get monthly historical data and future data
        // this month: previous
        const yesterday = new Date(currentYear, currentMonth, currentDate.getDate() - 1);
        await getEventsGroupByUrl(HISTORY_EVENT_GROUP_URL, { dateStart: fDate(firstDay), dateEnd: fDate(yesterday) });
        // this month: future
        await getEventsGroupByUrl(BASE_EVENT_GROUP_URL, { dateStart: dateString, dateEnd: fDate(lastDay) });
      } else {
        // future events
        lastDay = new Date(year, month, 0);
        await getEventsGroupByUrl(BASE_EVENT_GROUP_URL, { dateStart: dateString, dateEnd: fDate(lastDay) });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getEvents(date) {
//   const dateString = date;
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const currentDate = new Date();
//       currentDate.setHours(0, 0, 0, 0);
//       // console.log('current', currentDate);
//       // check if history previous month
//       const split = dateString.split('/');
//       const day = Number(split[0]);
//       const month = Number(split[1]);
//       const year = Number(split[2]);
//       const chosenDate = new Date(year, month - 1, day);
//       // console.log('chosen', chosenDate);
//       const currentMonth = currentDate.getMonth();
//       const currentYear = currentDate.getFullYear();
//       let firstDay = new Date(currentYear, currentMonth, 1);
//       let lastDay = new Date(currentYear, currentMonth + 1, 0);
//       if (currentDate.getTime() > chosenDate.getTime() && month !== currentMonth+1) {
//         // console.log('past events');
//         firstDay = new Date(year, month - 1, 1);
//         lastDay = new Date(year, month, 0);
//         await getEventsByUrl(HISTORY_EVENT_URL, { 'dateStart': fDate(firstDay), 'dateEnd': fDate(lastDay) });
//       } else if (month === currentMonth + 1 && year === currentYear) {
//         // get monthly historical data and future data
//         // console.log('this month: previous');
//         await getEventsByUrl(HISTORY_EVENT_URL, { 'dateStart': fDate(firstDay), 'dateEnd': dateString });
//         // console.log('this month: future');
//         await getEventsByUrl(BASE_EVENT_URL, { 'dateStart': dateString, 'dateEnd': fDate(lastDay) });
//       } else {
//         // console.log('future events');
//         await getEventsByUrl(BASE_EVENT_URL, { 'dateStart': dateString, 'dateEnd': fDate(lastDay) });
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
// ----------------------------------------------------------------------

export function createEvent(newSchedule) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/scheduled/create/', newSchedule);
      dispatch(slice.actions.createEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updateEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/calendar/events/update', {
        eventId,
        updateEvent,
      });
      dispatch(slice.actions.updateEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId, eventUuid, eventDate) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteEventSuccess({ eventId, asset_uuid: eventUuid, date: eventDate }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async () => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}
