// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  plusMath: getIcon('ic_plus_math'),
  setting: getIcon('ic_setting'),
  share: getIcon('ic_share'),
  list: getIcon('ic_list'),
  notification: getIcon('ic_notification'),
  staff: getIcon('ic_staff'),
  campaign: getIcon('ic_marketing'),
};

const navConfig = (isTenant = false) => {
  const result = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: '',
      items: [
        { title: 'general', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
        {
          title: 'campaign',
          path: PATH_DASHBOARD.campaign.root,
          icon: ICONS.campaign,
          children: [
            { title: 'multi_channel', path: PATH_DASHBOARD.campaign.multi },
            { title: 'feed', path: PATH_DASHBOARD.campaign.feed },
          ],
        },
        // { title: 'asset', path: PATH_DASHBOARD.post.list, icon: ICONS.list },
        { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.booking },
        {
          title: 'analytic',
          path: PATH_DASHBOARD.general.analytics,
          icon: ICONS.analytics,
        },
        {
          title: 'email_marketing',
          path: PATH_DASHBOARD.email.root,
          icon: ICONS.mail,
        },
        {
          title: 'push_notification',
          path: PATH_DASHBOARD.notification.root,
          icon: ICONS.notification,
          info: <Label color="error">Soon</Label>,
        },
        // {
        //   title: 'blog',
        //   path: PATH_DASHBOARD.blog.posts,
        //   icon: ICONS.share,
        //   info: <Label color="error">Soon</Label>,
        // },
      ],
    },

    // QUICK ACTION
    // ----------------------------------------------------------------------
    {
      subheader: ' ',
      items: [
        // ASSET CREATE
        // {
        //   title: 'create_asset',
        //   path: PATH_DASHBOARD.post.root,
        //   icon: ICONS.plusMath,
        //   children: [
        //     { title: 'real_estate', path: PATH_DASHBOARD.post.newRealEstate },
        //     { title: 'social', path: PATH_DASHBOARD.post.newSocial },
        //     { title: 'web', path: PATH_DASHBOARD.post.newWeb },
        //   ],
        // },
        // SITE ACCOUNT
        {
          title: 'account',
          path: PATH_DASHBOARD.account.list,
          icon: ICONS.user,
        },
        // STAFF FOR SME
        {
          title: 'staff',
          path: PATH_DASHBOARD.staff.list,
          icon: ICONS.staff,
        },
        // SETTINGS
        {
          title: 'setting',
          path: PATH_DASHBOARD.user.account,
          icon: ICONS.setting,
        },
      ],
    },
  ];

  // Modify
  // Staff tab
  const indexToStaff = result[1].items.findIndex((item) => item.title === 'staff');

  if (!isTenant && indexToStaff !== -1) {
    result[1].items.splice(indexToStaff, 1);
  }

  return result;
};

export default navConfig;
