import { useContext } from 'react';
import { WebSocketContext} from '../contexts/WSContext';

const useWebSocket = () => {
  const context = useContext(WebSocketContext);

  if (!context) throw new Error('Websocket context must be use inside WebSocketProvider');

  return context;
};

export default useWebSocket;
