import { PropTypes } from 'prop-types';
import { memo } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

FeedSourceIcon.propTypes = {
  color: PropTypes.string,
};

function FeedSourceIcon({ color, ...other }) {
  return (
    <Box {...other}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={color || '#000000'} stroke="none">
          <path
            d="M1885 4599 c-501 -51 -959 -363 -1196 -812 -260 -496 -230 -1102 80
      -1563 226 -338 561 -565 968 -656 134 -31 472 -33 603 -4 242 52 439 141 650
      294 16 12 30 -4 145 -160 71 -95 160 -211 198 -258 84 -102 849 -873 892 -900
      43 -27 97 -34 142 -20 48 16 199 160 224 215 22 47 23 65 8 120 -9 34 -70 100
      -387 418 -427 429 -511 505 -775 700 -181 135 -187 140 -173 161 8 11 37 54
      63 93 l48 73 318 0 317 0 0 175 0 175 -241 0 -241 0 6 28 c39 180 48 271 43
      442 -4 135 -11 198 -30 278 l-24 102 159 0 158 0 0 170 0 170 -232 1 -233 0
      -53 82 c-303 469 -867 735 -1437 676z m-95 -929 l0 -170 -300 0 -300 0 0 170
      0 170 300 0 300 0 0 -170z m600 0 l0 -170 -170 0 -170 0 0 170 0 170 170 0
      170 0 0 -170z m628 96 c46 -65 141 -238 142 -258 0 -5 -115 -8 -255 -8 l-255
      0 0 170 0 170 158 0 157 0 53 -74z m-1478 -696 l0 -170 -172 2 -173 3 -3 168
      -2 167 175 0 175 0 0 -170z m1694 133 c3 -21 6 -81 6 -133 0 -52 -3 -112 -6
      -132 l-7 -38 -718 0 -719 0 0 170 0 170 719 0 718 0 7 -37z m-1104 -728 l0
      -175 -467 2 -468 3 -3 160 c-1 87 0 165 2 172 4 10 100 13 471 13 l465 0 0
      -175z m1025 153 c-15 -48 -97 -196 -145 -260 l-50 -68 -285 0 -285 0 0 175 0
      175 386 0 385 0 -6 -22z"
          />
          <path d="M4100 3670 l0 -170 255 0 255 0 0 170 0 170 -255 0 -255 0 0 -170z" />
          <path d="M3840 3070 l0 -170 385 0 385 0 0 170 0 170 -385 0 -385 0 0 -170z" />
          <path d="M4270 2475 l0 -175 168 2 167 3 0 170 0 170 -167 3 -168 2 0 -175z" />
        </g>
      </svg>
    </Box>
  );
}

export default memo(FeedSourceIcon);
