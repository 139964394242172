import { useState, useEffect, useRef } from 'react';

// ----------------------------------------------------------------------

const DEFAULT_TIMER = {
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
};

export default function useCountdown(date) {
  const [countdown, setCountdown] = useState({
    days: '000',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const timerInterval = useRef(null);

  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    timerInterval.current = setInterval(() => setNewTime(), 1000);
    return () => clearInterval(timerInterval.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isExpiredCase2 = Object.keys(countdown).some((key) => `${countdown[key]}`.indexOf('-') !== -1);
    if (isExpiredCase2) {
      setCountdown({ ...DEFAULT_TIMER });
      setIsExpired(true);
      clearInterval(timerInterval.current);
    }
  }, [countdown]);

  const setNewTime = () => {
    const startTime = date;
    const endTime = new Date();
    const distanceToNow = startTime - endTime;

    const getDays = Math.floor(distanceToNow / (1000 * 60 * 60 * 24));
    const getHours = `0${Math.floor((distanceToNow % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))}`.slice(-2);
    const getMinutes = `0${Math.floor((distanceToNow % (1000 * 60 * 60)) / (1000 * 60))}`.slice(-2);
    const getSeconds = `0${Math.floor((distanceToNow % (1000 * 60)) / 1000)}`.slice(-2);

    setCountdown({
      days: getDays || '000',
      hours: getHours || '000',
      minutes: getMinutes || '000',
      seconds: getSeconds || '000',
    });
  };

  return { countdown, isExpired };
}

// Usage
// const {countdown,isExpired} = useCountdown(new Date('07/07/2022 21:30'));
