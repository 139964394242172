import { Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
// redux
import { markCompleted } from '../../../redux/slices/quest';
import { useSelector } from '../../../redux/store';
// hooks
import useAuth from '../../../hooks/useAuth';
import useResponsive from '../../../hooks/useResponsive';
// components
import Iconify from '../../../components/Iconify';
// utils
import { PATH_DASHBOARD } from '../../../routes/paths';
import { numberWithCommas } from '../../../utils/formatNumber';

const NOTY_QUEST_COMPLETED_TYPE = 'quest_completed_to_client';

export default function FreePostPopover() {
  const { user, updateUserProfile } = useAuth();

  const currentPackage = user?.package_using;

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const { questCompleted, totalQuest, quests } = useSelector((state) => state.quest);

  const { currentTenant } = useSelector((state) => state.tenant);

  const { anotherNotiType } = useSelector((state) => state.noti);

  const isSmaller450 = useResponsive('between', null, 0, 450);

  useEffect(() => {
    (async () => {
      if (!isEmpty(anotherNotiType) && anotherNotiType.type === NOTY_QUEST_COMPLETED_TYPE) {
        const id = anotherNotiType.quest_id;
        // Mark is comleted
        markCompleted(id);
        const questCompleted = quests.find((item) => item.id === id);
        if (questCompleted) {
          // Display snackbar
          await updateUserProfile();
          enqueueSnackbar(`Bạn vừa hoàn thành nhiệm vụ: ${questCompleted.title}`, { variant: 'success' });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anotherNotiType]);

  const handleQuestRedirect = () => {
    navigate(PATH_DASHBOARD.quest.list);
  };

  const handlePaymentRedirect = () => {
    navigate(`${PATH_DASHBOARD.user.account}?activeTab=payment`);
  };

  return (
    <>
      {!isSmaller450 && currentTenant?.payment_method !== 'subscription' && !currentPackage ? (
        <Stack direction={'row'} alignItems={'center'} columnGap={'12px'}>
          <Badge badgeContent={totalQuest - questCompleted} color="error">
            <Stack
              direction={'row'}
              alignItems={'center'}
              columnGap={'8px'}
              sx={{
                fontSize: '13px',
                color: `${theme.palette.primary.main}`,
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '8px',
                px: '10px',
                py: '4px',
                fontWeight: '700',
                cursor: 'pointer',
              }}
              onClick={handleQuestRedirect}
            >
              <Iconify icon={'ph:game-controller-bold'} width={20} height={20} />
              {numberWithCommas(user?.promo + user?.reward, '.')}
            </Stack>
          </Badge>

          <Stack
            direction={'row'}
            alignItems={'center'}
            columnGap={'8px'}
            sx={{
              fontSize: '13px',
              color: '#fff',
              background: `${theme.palette.primary.main}`,
              px: '10px',
              py: '4px',
              borderRadius: '8px',
              fontWeight: '700',
              cursor: 'pointer',
            }}
            onClick={handlePaymentRedirect}
          >
            <Iconify icon={'bx:dollar-circle'} width={20} height={20} />
            {numberWithCommas(user?.balance, '.')}
          </Stack>
        </Stack>
      ) : (
        ''
      )}
    </>
  );
}
