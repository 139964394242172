import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ idleTime = 1 }) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    setIdle(true);
  };

  const handleActive = () => {
    setIdle(false);
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    debounce: 500,
    onActive: handleActive,
  });

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleTimeout;
