import axios from 'axios';
// routes
import { PATH_AUTH } from '../routes/paths';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const tryRefreshToken = async (refreshToken) => {
  const rs = await axiosInstance.post('api/token/refresh/', {
    refresh: refreshToken,
  });
  return rs.data;
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    if (originalConfig.url === 'api/token/refresh/' && error?.response?.status === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      // eslint-disable-next-line no-alert
      alert('Phiên đăng nhập của bạn đã hết hạn. Vui lòng đăng nhập lại.');
      window.location.href = PATH_AUTH.login;
    } else if (error.response && error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          const newToken = await tryRefreshToken(refreshToken);
          const { access, refresh } = newToken;
          localStorage.setItem('accessToken', access);
          localStorage.setItem('refreshToken', refresh);
          return axiosInstance(originalConfig);
        }
      } catch (_error) {
        // eslint-disable-next-line no-alert
        return Promise.reject(_error);
      }
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export const generateArray = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

export default axiosInstance;

/**
 *  GET ALL STRUCTURE
 */

export const getAllItemByEndPoint = async (endPoint, params = {}) => {
  try {
    // first call
    const {
      data: { results: firstResult, next, count },
    } = await axiosInstance.get(endPoint, { params });

    let othersResults = [];

    if (next) {
      const pageSize = firstResult.length;
      const concurrency = Math.ceil(count / pageSize);
      const temp = generateArray(2, concurrency, 1);

      const promises = temp?.map(async (item) => {
        const newParams = { page: item };
        const response = await axiosInstance.get(endPoint, {
          params: newParams,
        });
        return response?.data?.results || [];
      });

      const results = await Promise.all(promises);
      results?.forEach((item) => {
        othersResults = [...othersResults, ...item];
      });
    }
    return [...firstResult, ...othersResults];
  } catch (error) {
    console.log(error);
    return [];
  }
};
