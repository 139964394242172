import { PropTypes } from 'prop-types';
import { memo } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------
SendLaterIcon.propTypes = {
  color: PropTypes.string,
};

function SendLaterIcon({ color, ...other }) {
  return (
    <Box {...other}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="40.000000pt"
        height="40.000000pt"
        viewBox="0 0 40.000000 40.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
        <g transform="translate(0.000000,40.000000) scale(0.007813,-0.007813)" fill={color || '#000000'} stroke="none">
          <path
            d="M2216 4629 c-323 -42 -640 -160 -906 -339 -121 -81 -196 -144 -327
-273 l-112 -110 -3 280 -3 280 -28 36 c-37 49 -75 67 -139 67 -40 0 -62 -6
-89 -24 -71 -48 -69 -34 -69 -616 0 -586 -2 -568 73 -618 l41 -27 480 -3 c265
-2 504 -1 533 3 57 7 104 34 137 78 33 44 29 133 -7 181 -51 66 -49 66 -419
66 l-333 1 150 147 c83 81 188 176 235 211 235 174 536 295 825 332 136 17
431 6 560 -20 539 -111 997 -464 1235 -954 272 -560 227 -1222 -119 -1739
-266 -398 -660 -658 -1146 -755 -71 -14 -138 -18 -315 -18 -198 0 -238 3 -335
24 -335 71 -606 210 -857 440 -83 75 -133 98 -191 86 -87 -19 -145 -98 -133
-182 8 -59 35 -94 149 -194 619 -544 1529 -665 2282 -304 431 207 791 577 987
1016 126 283 180 539 180 859 0 320 -54 576 -180 859 -293 655 -909 1112
-1625 1206 -128 16 -415 19 -531 4z"
          />
          <path
            d="M2472 3660 c-18 -11 -41 -34 -52 -52 -19 -32 -20 -52 -20 -565 0
-403 3 -539 12 -559 12 -25 820 -683 876 -713 11 -6 45 -11 74 -11 63 0 109
24 138 72 26 44 27 131 1 173 -10 17 -190 168 -399 335 l-382 305 0 465 c0
446 -1 466 -20 498 -30 48 -75 72 -140 72 -37 0 -66 -6 -88 -20z"
          />
        </g>
      </svg>
    </Box>
  );
}

export default memo(SendLaterIcon);
