import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { dispatch } from '../store';

// ----------------------------------------------------------------------
// NOTE CURRENTLY WE HAVE ONLY SUPPORT PRE-SAVE DATA ASSET CREATE FORM
/**
 * Each item in unCompletedForms state below have structure
 * {
 *    path: sub path of react router to page or component,
 *    formValues: {} object values of react hook form + yup
 * }
 */

// ----------------------------------------------------------------------

const initialState = {
  unCompletedForms: [],
};

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateUnCompletedForm(state, action) {
      const { path, formValues } = action.payload;
      const temp = [...state.unCompletedForms];
      // console.log('New data driven to redux');
      const index = temp.findIndex((item) => item?.path === path);
      if (index !== -1) {
        // Update
        temp[index] = { ...action.payload };
      } else {
        // Add new one
        temp.push({ path, formValues });
      }
      state.unCompletedForms = uniqBy([...temp], 'path') || [];
    },

    markAsCompleted(state, action) {
      const { path } = action.payload;
      const temp = [...state.unCompletedForms];
      const index = temp.findIndex((item) => item?.path === path);
      if (index !== -1) {
        // Remove after completed form
        temp?.splice(index, 1);
        state.unCompletedForms = uniqBy([...temp], 'path') || [];
      }
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export async function addNewUnCompletedForm(unCompletedForm) {
  dispatch(slice.actions.updateUnCompletedForm(unCompletedForm));
}

export async function markAsCompletedForm(path) {
  dispatch(slice.actions.markAsCompleted({ path }));
}
