// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  complete_infomation: 'Please complete your information for the best experience.',
  error: {
    required: 'This is a required field',
    format: {
      email: 'Must enter correct email format',
      phone: 'Must enter the correct phone number format',
    },
  },
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  commons: {
    day: 'd',
    hour: 'h',
    minute: 'm',
    second: 's',
    package: 'Package',
    expired: 'Your {{name}} has expired',
  },
  modal: {
    btn: {
      back: 'Back',
      close: 'Close',
      done: 'Done',
      next: 'Next',
    },
    popup: {
      linking_again: 'Linking Again',
    },
    title: {
      error_linking: 'Error linking account',
    },
  },
  image_upload: {
    upload: 'Upload here',
    delete: 'Delete all',
    update: 'Update image',
  },
  pwa: {
    title: 'Notification Feature',
    step_one: `1. Click the 'Share' button`,
    step_two: `2. Select 'Add to Homescreen'`,
    content: `
      1.Access your website updates on your Safari browser on Apple mobile devices 16.4 or later.
      2.Click the Share button on Safari browser.
      3.Click the Add to Home screen option.
      4.Save the app on your device.
      5.Open the app from the home screen.
      6.Sign up for notifications to use our new feature.
    `,
  },
  form: {
    invalid_format: 'Please enter the correct format',
    min: 'Enter at least {{min}} characters',
    max: 'Enter maximum {{max}} characters',
    required: 'This is a required field',
    array: {
      min: 'Must be at least {{min}}',
      max: 'Max {{max}}',
    },
    image: {
      min: 'Upload at least {{min}} photos',
      max: 'upload maximum {{max}} photos',
    },
    nudity: 'Image contains nudity',
  },
  source_filter: {
    private: 'Your source',
    organization: 'Organization',
    public: 'Shared',
    legit: 'Legit',
    exclusive: 'Exclusive',
    all: 'All',
    btn: {
      apply: 'Apply',
      reset: 'Reset',
    },
  },
  partnership: {
    title: 'Partnership',
    content: 'Enjoy value, efficiency, and discount with a partner account within the Uptin ecosystem.',
  },
  progress: 'In progress, please wait a few seconds!',
  feature_disable: 'You cannot use this feature',
  asset_required: 'Please save content first before using this feature',
  task_running: 'Same process was running before please wait!',
  login: {
    main_title: 'End-to-End marketing platform',
    heading: 'Login - Register Uptin',
    heading_tenant: 'Login {{name}}',
    sub_heading: 'Log in or register with',
    by_email: 'Login with email',
    remember: 'Remember me',
    forgot: 'Forgot password',
    test_account: 'Demo account: {{email}} / Password: {{password}}',
    or: 'OR',
    btn: {
      login: 'Login',
    },
    form: {
      password: 'Password',
    },
    message: {
      invalid: 'Email or password is incorrect',
      suggest: 'You can sign in with Google instead of Email and Password',
    },
  },
  forgot_password: {
    heading: 'Forgot password?',
    description:
      'Please enter the email address associated with your account, we will send a link to reset your password.',
    form: {
      error: {
        email: {
          required: 'Email is required',
          format: 'Must enter the correct email format',
        },
      },
    },
    btn: {
      reset: 'Reset Password',
      back: 'Back',
    },
  },
  reset_password: {
    title: 'Request sent successfully!',
    form: {
      field: {
        password: 'Password',
        re_password: 'Re-password',
      },
      error: {
        password: {
          required: 'Password is required',
        },
        re_password: {
          required: 'Re-entering password is required',
          match: 'Re-entered password does not match',
        },
      },
    },
    btn: {
      change: 'Change Password',
    },
  },
  confirm_staff: {
    title: 'Testing in progress...',
  },
  nav: {
    general: `Dashboard`,
    asset: `Content`,
    calendar: `Calendar`,
    analytic: `Market`,
    push_notification: `Push notification`,
    email_marketing: `Email marketing`,
    create_asset: `Create content`,
    blog: `Blog`,
    real_estate: `Real estate`,
    social: `Social media`,
    web: 'Website',
    account: `Account`,
    setting: `Setting`,
    payment: `Payment`,
    change_password: `Change password`,
    logout: `Logout`,
    staff: 'Staff',
    campaign: 'Campaign',
    feed: 'Feed',
    multi_channel: 'Multichannel',
  },
  header: {
    notification: {
      notification: 'Notification',
      unread: `You have # unread notifications`,
      all_notification: 'View all notifications',
    },
    task: {
      view_result: 'Click to see results',
      empty: 'No results',
      task_completed: 'Some progress has been completed!',
    },
  },
  component: {
    delete_account: {
      content: `, what happens when you "<span style="font-weight: bold">Delete account</span>" ?`,
      note: {
        first: 'Uptin only deletes login accounts',
        second: 'Accounts linked to platforms still retain',
      },
      btn: {
        back: 'Back',
        confirm: 'Confirm',
      },
    },
    trial: {
      group: 'Are you working in a group?',
      enterprise: 'Are you a business owner?',
      try_package: 'Try business package',
      try_seven_days: 'Trial {{days}} days',
      content: 'Your account balance can be used to pay later.',
      btn: {
        accept: 'Agree',
        cancel: 'Cancel',
      },
    },
  },
  dashboard: {
    app: {
      welcome: 'Welcome!',
      content: 'Choose to post or do tasks to receive FREE money.',
      btn: {
        post: 'Post now',
        linking: 'Linking now',
        quest: 'Daily tasks',
      },
      tab: {
        day: 'Day',
        month: 'Month',
      },
      chart: {
        total_post: 'Total post',
        view: 'View',
        display: 'Impressions',
        statistics: 'Monthly statistics',
        statistics_sub: 'compared with the previous month',
        spend: 'Spending Statistics',
        schedule_success: 'Successful post',
        schedule_fail: 'Failed post',
        balance: 'Balance',
        payment: 'Top-up',
        disburse: 'Disburse',
        sell: 'Sell',
        rent: 'Rent',
      },
    },
    account: {
      save: 'Save changes',
      send_request: 'Send request',
      breadcrumb: {
        home: 'Dashboard',
        user: 'User',
        setting: 'Setting',
      },
      tab: {
        general: 'General',
        payment: 'Payment',
        sme: 'Business package',
        notification: 'Notification',
        social: 'Social Media',
        password: 'Password',
      },
      general: {
        avatar: {
          upload: 'Upload here',
          update_type: '*.jpeg, *.jpg, *.png . images allowed',
          update_size: 'Maximum capacity 1Mb',
        },
        public: 'Public profile',
        delete: 'Delete account',
        form: {
          first_name: 'First name',
          last_name: 'Last name',
          phone: 'Phone number',
          city: 'City',
          district: 'District',
          company: 'Company',
          position: 'Position',
        },
        required: 'Please fill in here',
        error: 'Update error!',
        success: 'Update successful!',
        delete_account: 'Account deletion succeeded! The system will return to the login page in a few seconds!',
      },
      payment: {
        recharge: 'Recharge',
        payment: 'Payment',
        method: 'Payment methods',
        wallet: 'Wallet',
        history: 'History',
        empty: 'Empty',
        add_method: 'Add new method payment',
        topup: 'Top-up',
        proceed: 'Proceed',
        package: 'Package',
        sme: {
          member: {
            exact: '{{numb}} users',
            range: '{{from}} - {{to}} users',
            smaller: 'Below {{numb}} users',
            larger: '{{numb}}+ users',
          },
          target: {
            group: 'For groups',
            sme: 'For SME',
            enterprise: 'For large enterprise',
          },
          month: 'Month',
          year: 'Year',
          discount: ' (Save {{percent}}%)',
        },
      },
      notifications: {
        activity: {
          comment: 'Email me when someone comments on my post',
          feeling: 'Email me when someone reacts to my post',
          follow: 'Email me when someone follows me',
        },
        application: {
          update: 'System news and announcements',
          blog: 'Weekly software updates and new blog posts',
        },
        success: 'Update completed!',
      },
      change_password: {
        old_password: 'Current password',
        new_password: 'New password',
        re_new_password: 'Repeat new password',
        error: {
          old_password_required: 'Old password is required',
          new_password_required: 'New password is required',
          new_password_length: 'New password must be at least 6 characters',
          re_new_password: 'Re-entered password does not match',
        },
      },
    },
    analytic: {
      location: 'Location',
      breadcrumb: {
        market: 'Market',
        overview: 'Dashboard',
      },
      form: {
        city: 'City',
        district: 'District',
        ward: 'Ward',
        error: {
          required: 'Please fill in here',
        },
      },
      btn: {
        search: 'Analytic',
      },
      tab: {
        sell: 'Sell',
        rent: 'Rent',
        facade: 'Facade',
        alley: 'In the alley',
      },
      chart: {
        compare: 'compared to the previous month',
        title: 'Market in 3 years',
        average_price: 'Average price',
        number_posts: 'Number posts',
      },
      average_price: 'Average price',
      number_posts: 'Number posts',
      compare: 'compared to the previous quarter',
      helps: 'Analytics helps you',
      develop: 'This feature is in development.',
    },
    asset: {
      breadcrumb: {
        asset: 'Content',
        overview: 'Dashboard',
      },
      stat: {
        success: 'Success',
        scheduled: 'Scheduled',
        total: 'Total posts',
        month: 'post in month',
        sum: 'total post',
        post_total: 'Total posts',
        post_month: 'post in month',
        post_sum: 'total post',
      },
      btn: {
        remove_filter: 'Reset',
        real_estate: 'Real estate',
        social: 'Social media',
        edit: 'Edit',
        schedule: 'Post',
        detail: 'Detail',
        web: 'Website',
        converts: {
          mxh: 'Clone to SOCIAL with AI',
          web: 'Clone to WEBSITE with AI',
          pnt: 'Clone to PNT with AI',
        },
      },
      filter: {
        type: 'Type',
        start_day: 'Start day',
        end_day: 'End day',
        search: 'Search by title',
      },
      table: {
        head: {
          type: 'Type',
          avatar: 'Avatar',
          title: 'Title',
          city: 'Location',
          created_day: 'Created at',
          updated_day: 'Updated at',
        },
      },
      detail: {
        title: 'Detail post',
        table: {
          head: {
            platform: 'Platform',
            title: 'Title',
            date: 'Date',
            display: 'Display',
            status: 'Status',
            view: 'View',
            impression: 'Impression',
          },
        },
        tab: {
          all: 'All',
          active: 'Active',
          inactive: 'Inactive',
          hidden: 'Hidden',
          remove: 'Removed',
        },
        btn: {
          back: 'Back',
          link: 'Go to the post',
        },
        dialog: 'Filter post',
        notification: 'Notification',
        collapse: 'Collapse',
      },
      message: {
        cloned: 'This post has been cloned',
      },
    },
    calendar: {
      form: {
        table: {
          head: {
            platform: 'Platform',
            id_balance: 'ID/Balance',
            select_package: 'Select package',
            title_balance: 'Title/Balance',
            note: 'Note',
            package: 'Package',
            date_post: 'Date post',
            day_post: 'Day post',
            time_post: 'Time post',
            name_avatar: 'Name/Avatar',
          },
          body: {
            your_page: 'Your page',
            your_group: 'Your group',
            other_group: "Someone else's group",
            personal: 'Personal',
            organization: 'Organization',
            instagram_page: 'Instagram page',
          },
        },
        btn: {
          edit: 'Edit',
          back: 'Back',
          done: 'Done',
          link: 'Go to post',
        },
        tab: {
          real_estate: 'Real estate',
          social: 'Social media',
          web: 'Website',
        },
        current_asset: 'Current content',
        spam: "We add a 5 minute delay between messages so you don't get marked as spam",
        collapse: 'Collapse',
        type: 'Type',
      },
      breadcrumb: {
        home: 'Dashboard',
        calendar: 'Calendar',
      },
      btn: {
        schedule: 'Schedule',
      },
      title: {
        dialog: 'Filter post',
        notification: 'Notification',
      },
      message: {
        selects: 'Choose at least 1 platform to post news',
        invalid_time: 'Please select the correct scheduled date',
      },
    },
    site: {
      action: {
        link_account: 'real estate platform',
      },
      breadcrumb: {
        home: 'Dashboard',
        account: 'Account',
      },
      dialog: {
        cms_title: 'Linking CMS platform',
      },
      popup: {
        title: 'GOOGLE API DISCLOSURE',
        content: `This app complies with the Google API Services User Data Policy, including the Limited Use requirements. This means that we will only use the data you provide us with to provide the services you have requested, and we will not share your data with any third parties without your consent.</br></br>You can learn more about the Google API Services User Data Policy here: <a href='https://developers.google.com/terms/api-services-user-data-policy' target='_blank'>https://developers.google.com/terms/api-services-user-data-policy</a>.</br></br>If you have any questions or concerns about our privacy practices, please contact us at: admin@uptin.vn</br></br>Thank you for using our app!`,
      },
      popup_remove: {
        title: 'Account deletion confirmation',
        content: `Are you sure you want to delete this account?`,
      },
      btn: {
        add: 'Add',
        real_estate: 'Real estate',
        social: 'Social media',
        collapse: 'Collapse',
        view: 'View',
        edit: 'Edit',
        connect: 'Connect',
        close: 'Close',
        save: 'Check and save',
        analytic: 'Google Analytics (GA4)',
        confirm: 'Confirm',
        cancel: 'Cancel',
        remove: 'Delete',
      },
      tab: {
        all: 'All',
        real_estate: 'Real estate',
        social: 'Social media',
        website: 'CMS',
      },
      form: {
        table: {
          head: {
            platform: 'Platform',
            id_balance: 'ID/Balance',
            name: 'Name account',
            status: 'Status',
            name_avatar: 'Name/Avatar',
          },
          body: {
            connected: 'Connected',
            disconnect: 'Disconnect',
          },
        },
        field: {
          password: 'Password',
          app_password: 'Application password',
          access_token: 'Access token',
          website: 'Website url',
          blog_url: 'Blog url',
          username: 'Username',
          is_branch: 'Sub-Account/Business',
          allow_create: 'Auto create',
          phone: 'Telephone number',
          name: 'Fullname',
        },
        error: {
          required: 'Please fill in here',
          url_invalid: 'Please fill valid url',
        },
      },
      message: {
        site_inactive: '{{name}} is temporarily closed',
        analytic_success: 'Integrated google analytic successfully',
        remove_success: 'Removed account successfully',
      },
    },
    social: {
      popup_facebook: {
        title: 'FACEBOOK API DISCLOSURE',
        content: `
        <ol><li>Authentication and Authorization: Uptin would integrate Meta's authentication mechanisms to allow users to log in or connect their Meta accounts securely. This involves obtaining the necessary permissions from users to access their profile and manage their groups and pages.</li>
  <li>Group API Integration: Uptin would utilize the Group API to retrieve a list of groups and pages the user is a member of or is an admin.</li>
  <li>Utilizing publish_to_groups Endpoint: Uptin might incorporate the publish_to_groups endpoint to enable users to post content to specific groups or pages through their platform. This involves:</li></ol><ul><li>Allowing users to compose and schedule posts or media.</li><li>Facilitating the selection of groups where the content will be published.</li><li>Ensuring that the content created complies with Meta's community standards and policies before initiating the publishing process.</li></ul>`,
      },
      platform: 'Add social media platform',
      message: {
        empty_page: 'You have not joined any pages yet',
        empty_group: 'You have not joined any groups yet',
        empty_installed: `Couldn't find information about groups that added the Uptin app`,
        empty_personal: `Couldn't find information about personal`,
        empty_organization: `Couldn't find information about organizations`,
      },
      tab: {
        fanpage: 'Fanpage',
        own_group: 'Your group',
        installed_group: 'The group connected to Uptin',
        personal: 'Personal',
        organization: 'Organizations',
      },
      btn: {
        prev: 'Previous',
        next: 'Next',
        back: 'Back',
        copy_link: 'Copy group link',
        auto_linking: 'Auto linking',
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      form: {
        search: 'Search by group name or tag...',
      },
      snackbar: {
        connected: 'Established connection',
        copied: 'Copied path!',
        removed: 'Removed connection',
      },
      popup: {
        content: `To be able to post to the group “<span style="font-weight: bold">#</span>", you need to contact the Group Administrator and ask them to <span style="font-weight: bold">add Uptin app</span> in the App section of the group. Find the group on facebook or copy this link and paste it in your browser.`,
        remove_content: `Are you sure that you want to remove the connected social from Uptin?`,
      },
    },
    create_asset: {
      breadcrumb: {
        home: 'Dashboard',
        asset: 'Content',
        create_asset: 'Create content',
        edit_asset: 'Edit content',
      },
      message: {
        form_invalid: 'Please fill in all information',
      },
      real_estate: {
        title_create: 'Create real estate content',
        title_edit: 'Edit real estate content',
        tab: {
          general: 'General information',
          detail: 'Detail information',
        },
        btn: {
          next_step: 'Next step',
          next: 'Next',
          save: 'Save',
          complete: '{{action}} and schedule',
          edit: 'Update',
          create: 'Create',
          amenitie: 'Near amenities',
          convince: 'Convince',
          back: 'Back',
          close: 'Close',
          add_amenitie: 'Add to description',
        },
        message: {
          create_success: 'Create content successfully',
          edit_success: 'Update content successfully',
          check_error: 'Please double check the information: ',
        },
        form: {
          field: {
            asset_type: 'Type of real estate',
            asset_detail: 'Real estate details',
            city: 'City',
            district: 'District',
            ward: 'Ward',
            street: 'Street',
            price: 'Price (VNĐ)',
            roi_percentage: 'Return Of Investment %',
            area: 'Area (m2)',
            actual_area: 'Usable area (m2)',
            url: 'Url',
            share_source: {
              title: 'Shared source',
              private: 'Private',
              organization: 'Organization',
              public: 'Public',
            },
            demand: {
              title: 'Demand*',
              sell: 'Sell',
              rent: 'Lease',
              buy: 'Buy',
              tenant: 'Rent',
              assign: 'Assign',
            },
            who: {
              title: 'You are*',
              broker: 'Agency',
              owner: 'Owner',
              developer: 'Developer',
              investor: 'Investor',
            },
            bedroom: 'Bedroom',
            toilet: 'Toilet',
            legal_document: 'Legal documents',
            contact_info: 'Contact info',
            address: 'Address',
            hand_overs: {
              project: 'Project',
              under_construction: 'Under construction',
              slacking: 'Incomplete',
              handing_over: 'Handing over',
              complete: 'Complete',
              red_book: 'Red book',
              house_ownership: 'Pink book',
              sale_contract: 'Sale contract',
              valid_documents: 'Valid documents',
            },
            title: 'Title',
            short_title: 'Short (50-70 characters)',
            description: 'Detailed description (up to 1500 characters)...',
            image: {
              title: 'Images',
              heading: 'Minimum 3 photos - Up to {{asset_image_count}} photos',
              first_content: '- Drag and drop or click to select photos',
              second_content: '- Maximum image size 3Mb',
              third_content: '- The first photo from left to right will be the cover photo',
            },
            hidden: 'Hidden',
            house_number_hidden: 'Hide house address number in posts',
            shown: 'Shown',
            house_number_shown: 'Show house address number in posts',
            converts: {
              mxh: 'Automatically create social media post',
              web: 'Automatically create website post',
              pnt: 'Automatically create push notification post',
            },
          },
          error: {
            select: 'Please choose',
            required: 'Please fill in here',
            price: 'Please enter the correct price',
            area: 'Please enter the correct area',
            toilet: 'Please enter the correct toilet',
            bedroom: 'Please enter the correct bedroom',
            url_invalid: 'Please fill valid url',
            roi_percentage: {
              min: 'Roi Percentage should be at least 0%',
              max: 'Roi Percentage up to 30%',
            },
            short_title: {
              min: 'Title should be at least 50 characters',
              max: 'Title should be at most 70 characters',
            },
            description: {
              min: 'Detailed description of at least 200 characters',
              max: 'Detailed description up to 1500 characters',
            },
            images: {
              min: 'Please upload at least 3 photos',
              max: 'Please upload up to {{asset_image_count}} photos',
            },
          },
        },
      },
      social: {
        title_create: 'Compose social media posts',
        title_edit: 'Editing social media posts',
        btn: {
          rewrite: 'Rewrite',
          save: 'Save',
          complete: '{{action}} and schedule',
          edit: 'Update',
          create: 'Create',
          google_format: 'Google Post Format',
          instagram_format: 'Instagram post format',
        },
        message: {
          create_success: 'Create content successfully',
          edit_success: 'Update content successfully',
          check_error: 'Please double check the information: ',
          hashtag_error: 'Add some keywords related to your problem',
        },
        form: {
          field: {
            keyword: 'Keywords "," separated by commas',
            description: 'Fill in something interesting...',
            title: 'Title',
            image: {
              title: 'Images',
              heading: 'Upload up to {{asset_image_count}} photos',
              first_content: '- Drag and drop or click to select photos',
              second_content: '- Maximum image size 3Mb',
              third_content: '- The first photo from left to right will be the cover photo',
            },
            converts: {
              mxh: 'Automatically create social media post',
              web: 'Automatically create website post',
              pnt: 'Automatically create push notification post',
            },
          },
          error: {
            required: 'Please fill in here',
            description: {
              min: 'Enter at least 50 characters',
              invalid: 'Content must be more than 50 characters (excluding emojis)',
            },
            images: {
              max: 'Please upload up to {{asset_image_count}} photos',
            },
          },
        },
      },
      web: {
        title_create: 'Compose website posts',
        title_edit: 'Editing website posts',
        btn: {
          preview: 'Preview',
          complete: 'Complete',
        },
        message: {
          create_success: 'Create content successfully',
          edit_success: 'Update content successfully',
          check_error: 'Please double check the information: ',
          hashtag_error: 'Add some keywords related to your problem',
        },
        form: {
          field: {
            title: 'Title',
            description: 'Description',
            content: 'Content',
            tags: 'Tags',
            meta_title: 'Meta title',
            meta_description: 'Meta description',
            meta_keywords: 'Keywords',
            template: 'Template',
            image: {
              title: 'Cover image',
              heading: 'Cover image',
              first_content: '-Drag and drop or click to select photos',
              second_content: '-Maximum image size 5Mb',
            },
            converts: {
              mxh: 'Automatically create social media post',
              web: 'Automatically create website post',
              pnt: 'Automatically create push notification post',
            },
          },
          error: {
            required: 'Please fill in here',
            title: {
              min: 'Title should be at least 30 characters',
              max: 'Title up to 50 characters',
            },
            description: {
              min: 'Enter at least 50 characters',
              invalid: 'Content must be more than 50 characters (excluding emojis)',
            },
            images: {
              max: 'Please upload up to 1 photos',
              min: 'Please upload at least 1 photo',
            },
            meta_keywords: {
              min: 'Keywords must have at least 1 items',
            },
            tags: {
              limit: 'The number of tags has exceeded the allowed limit',
              min: 'Tags must have at least 1 items',
            },
            template: {
              max: 'Template upto 200 characters',
            },
          },
        },
      },
    },
    notification: {
      you: 'You',
      breadcrumb: {
        general: 'Dashboard',
        notification: 'Notification',
        all_notification: 'All notifications',
      },
      menu: {
        setting: 'Notification setting',
        mark_read: 'Mark all as read',
      },
      btn: {
        collapse: 'Collapse',
      },
    },
    quest: {
      breadcrumb: {
        quest: 'Quest',
        general: 'Dashboard',
        user: 'User',
      },
      detail: 'Quest details:',
      multi: 'amount of money',
      vnd: 'VND',
      members: 'members',
      title: {
        reward_login_daily: 'Login daily',
        reward_daily_scheduled_bds: 'Post a real estate post',
        reward_daily_scheduled_mxh: 'Post a social media post',
        reward_daily_scheduled_web: 'Post a website post',
        reward_linking_account: 'Successfully linked account',
        promo_linking_facebook_group: 'Add Uptin app to Facebook group',
        promo_daily_topup: 'First recharge of the day',
        promo_first_topup: 'First deposit',
      },
      description: {
        reward_login_daily:
          '- Reward: {{moneyReceive}} VND\n- Requirements: Log in to Uptin through Google or Facebook\n- Task type: Daily',
        reward_daily_scheduled_bds:
          '- Reward: {{moneyReceive}} VND\n- Requirements: Schedule and successfully post 01 real estate listing per day\n- Task type: Daily',
        reward_daily_scheduled_mxh:
          '- Reward: {{moneyReceive}} VND\n- Requirements: Schedule and successfully post 01 social media listing per day\n- Task type: Daily',
        reward_daily_scheduled_web:
          '- Reward: {{moneyReceive}} VND\n- Requirements: Schedule and successfully post 01 website listing per day\n- Task type: Daily',
        reward_linking_account:
          '- Reward: {{moneyReceive}} VND\n- Requirements: Connect 1 real estate/social media account to Uptin for the first time and succeed.\n- Task type: One-time only',
        promo_linking_facebook_group:
          '- Reward: {{moneyReceive}} VND x Number of group members\n- Requirements: Must be the group admin and the first person to install the Uptin application in the Facebook group. The group must have over 100 members.\n- Task type: One-time only',
        promo_daily_topup:
          '- Reward: Double the deposit amount (maximum reward of 50,000 VND)\n- Requirements: Payment via Momo, VNPay, or bank transfer\n- Task type: Daily',
        promo_first_topup:
          '- Reward: Double the deposit amount (unlimited)\n- Requirements: Payment via Momo, VNPay, or bank transfer\n- Task type: One-time only',
      },
      status: {
        completed: 'Completed',
      },
    },
    staff: {
      breadcrumb: {
        staff: 'Staff',
        general: 'Overview',
        list: 'List',
      },
      table: {
        head: {
          avatar: 'Picture',
          name: 'Full name',
          status: 'Status',
          date_join: 'Join date',
          date_end: 'End date',
        },
        body: {
          pending: 'Pending',
          confirmed: 'In use',
          quit: 'Stop using',
          refuse: 'Refuse',
        },
      },
      form: {
        error: {
          required: 'Please fill in here',
          type_invalid: 'Incorrect format',
        },
      },
      btn: {
        request: 'Send request',
        add: 'Add staff',
        accept: 'Agree',
        collapse: 'Collapse',
        delete: 'Remove',
        resend: 'Resend',
      },
      delete: {
        title: 'Remove personnel {{email}} from service pack?',
        content:
          'The above user will no longer share your plan. However, you will only be able to add new staff after 7 days.',
      },
    },
    push_notification: {
      breadcrumb: {
        notification: 'Notification',
        overview: 'Dashboard',
        create_notification: 'Create notification',
      },
      tab: {
        list: 'List',
        approve: 'Pending Requests',
        audience: 'Audience',
        content: 'Content',
      },
      table: {
        head: {
          image: 'Image',
          title: 'Title',
          status: 'Status',
          budget: "Budget <br /> Recipient's",
          date_send: 'Delivery date',
          content: 'Content',
        },
      },
      form: {
        label: {
          demographic: 'Demographic',
          date_picker: 'Choose a date',
        },
        field: {
          area: {
            title: 'Area',
            northern: 'Northern',
            central: 'Central',
            southern: 'Southern',
          },
          city: 'City',
          career: {
            title: 'Carrer',
            broker: 'Broker',
            owner: 'Owner',
            developer: 'Developer',
            investor: 'Investor',
          },
          asset_type: 'Type of real estate',
          asset_detail: 'Real estate details',
          demand: {
            title: 'Demand',
            sell: 'Sell',
            rent: 'Lease',
            buy: 'Buy',
            tenant: 'Rent',
            assign: 'Assign',
          },
          all: 'All',
          image: {
            heading: 'Upload image',
            first_content: '-Drag and drop or click to select',
            second_content: '-Maximum image size 5Mb',
            third_content: '-The image is 256x256 px',
          },
          title: 'Title(6-80 characters)',
          content: 'Content(10-150 characters)',
          link: 'Notification link',
        },
        error: {
          required: 'Please fill in here',
          image: {
            required: 'Please upload a photo',
            only_one: 'Please upload 1 photo',
            format: 'Image must be 256x256 px',
          },
          title: {
            min: 'Enter at least 6 characters',
            max: 'Enter up to 80 characters',
          },
          content: {
            min: 'Enter at least 10 characters',
            max: 'Enter up to 150 characters',
          },
          link: {
            format: 'Please enter the correct link format',
          },
        },
      },
      preview: 'Preview',
      btn: {
        delete: 'Delete',
        approve: 'Approve',
        reject: 'Reject',
        collapse: 'Collapse',
        create_notification: 'Create notification',
        next: 'Next step',
        back: 'Back',
        complete: 'Submit',
      },
      status: {
        deliveried: 'Deliveried',
        canceled: 'Denied',
        pending: 'Pending',
        scheduled: 'Scheduled',
      },
      message: {
        create_success: 'Created notification success',
        deleted_success: 'Deleted success notification',
        deleted_fail: 'Deleted failure notification',
        approved_success: 'Approved success notification',
        approved_fail: 'Approve failed notification',
        denied_success: 'Rejected success notification',
        denied_fail: 'Reject failure notification',
      },
    },
    multi_channel: {
      steps: {
        source: 'Source',
        content: 'Content',
        channels: 'Channel',
        schedule: 'Schedule',
        btn: {
          next: 'Next',
          back: 'Back',
          save: 'Save',
          skip: 'Skip',
        },
        step_1: {
          industry: 'Industry',
          exclusive: 'Exclusive',
          industry_list: {
            real_estate: 'Real estate',
            ecommerce: 'Ecommerce',
          },
          level: 'Mode',
          source_name: 'Source name',
          data_from: 'Get data from',
          form: {
            images: {
              title: 'Upload 1 photo',
              text1: '- Drag and drop or click to select image',
              text2: '- Maximum image size 3Mb',
              text3: '- Representative image of the source',
            },
            errors: {
              required: 'This is a required field',
              images: {
                min: 'Select at least 1 photo',
                max: 'Select up to 1 photo',
              },
            },
          },
          message: {
            select_source: 'Please select a source',
            create_source_success: 'Successfully added source',
          },
          btn: {
            add: 'Add source',
          },
        },
        step_3: {
          cost: 'Estimated cost:',
          setting_name: 'Setting name',
          selected: 'Selected',
          quota: 'Quota',
          package: 'Package',
          types: {
            bds: 'Real estate',
            mxh: 'Social network',
            email: 'Email marketing',
            web: 'Website/Landing pages',
          },
          form: {
            errors: {
              required: 'This is a required field',
            },
          },
          btn: {
            create_setting: 'Create setting',
            add_setting: 'Add setting',
            add_email: 'Add email',
          },
          message: {
            select_channel: 'Please select a distribution channel',
            select_package: 'Please choose the appropriate package',
            select_platform: 'Please select at least 1 platform',
            create_setting_success: 'Customization created successfully',
          },
        },
        step_4: {
          right_away: 'Right away',
          later: 'Later',
          on: 'At',
          confirm: 'Confirm sending',
          message: {
            select_time: 'Please select delivery time',
          },
        },
      },
    },
    feed: {
      btn: {
        back: 'Back',
        next: 'Continue',
        save: 'Done',
        add: 'Add target',
      },
      step_1: {
        title: 'Target',
        filter: {
          type: 'Type',
          keywords: 'Search by name',
          all: 'All',
          page: 'Page',
          group: 'Group',
          website: 'Website',
        },
        add_affiliate_account: 'Add affiliate account',
        linking_social_media: 'Linking social media platform',
        linking_cms: 'Linking CMS platform',
      },
      step_2: {
        title: 'Setting',
        messages: {
          select_sources: 'Please select at least 1 source',
          double_check: 'Please check the information again',
          limit_keyword: 'Keyword maximum 200 characters',
          select_days: 'Please select at least 1 day of the week',
          created_success: 'The feed function has been successfully activated',
          server_error: 'An error occurred',
        },
        source: {
          title: 'Select sources:',
        },
        keyword: {
          title: 'Keywords:',
        },
        recurrence: {
          title: 'Recurrence:',
        },
        form: {
          fields: {
            days: 'Days in week',
            keyword: 'Keyword',
            time: 'At',
            end: 'End',
            ai: {
              title: 'AI rewrite tone',
              random: 'Random',
              add_hashtag: 'Add Hashtag',
              add_emoji: 'Add Emoji',
              standard: 'Standard',
              funny: 'Funny',
              creative: 'Creative',
              professional: 'Professional',
            },
            days_in_week: {
              monday: 'M',
              tuesday: 'T',
              wednesday: 'W',
              thursday: 'T',
              friday: 'F',
              saturday: 'S',
              sunday: 'S',
            },
          },
          errors: {
            required: 'This is a required field',
            days: {
              min: 'Choose at least 1 day',
            },
            keywords: {
              min: 'Enter at least 1 keyword',
              max: 'Enter up to 5 keywords',
            },
          },
        },
      },
    },
  },
  email: {
    sidebar: {
      compose: 'Compose',
      contact: 'Contact',
      campaigns: 'Campaign',
      template: 'Template',
      settings: 'Settings',
      quota: 'Usage',
    },
    btn: {
      create_campaign: 'Create campaign',
      create_group: 'Add contact group',
      create_contact: 'Add contact information',
      create_template: 'Create template',
      update: 'Update',
      create: 'Create',
      cancel: 'Cancel',
      check_verify: 'Check',
      add_verify: 'Add sender',
      delete: 'Delete',
      edit: 'Edit',
      resend: 'Resend',
      schedule: 'Schedule',
      send_now: 'Send now',
      save: 'Save',
    },
    tabs: {
      domain_verify: 'Domain verification',
      email_verify: 'Email verification',
    },
    table: {
      head: {
        domain: 'Domain',
        verified: 'Verified',
        bounce_domain: 'Bounce domain',
        email: 'Email',
      },
      body: {
        verified: 'Verified',
        unverified: 'Unverified',
      },
    },
    message: {
      error: 'Have some error happened',
      remove_success: 'Deleted successfully',
      resend_success: 'Sent successfully',
    },
    verify: {
      btn: {
        continue: 'Continue',
        completed: 'Complete',
        back: 'Back',
        verify: 'Verify record',
        value: 'Value',
        copy: 'Copy',
      },
      email: {
        btn: {
          resend: 'Resend',
        },
        form: {
          errors: {
            email_format: 'Invalid email format',
            required: 'This is required field',
          },
        },
        content: {
          step1: {
            title: 'Enter email address',
            content: 'Enter the email address you want to use as your sending email.',
          },
          step2: {
            title: 'Check mailbox',
            content: `We've sent you a verification email. Check your mailbox and click the confirmation link. If you didn't receive it, please check your other mailbox folders or click the resend button.`,
            resend: 'Resend verification email',
          },
        },
      },
      domain: {
        form: {
          errors: {
            email_format: 'Invalid email format',
            domain_format: 'Invalid domain format',
            required: 'This field is required',
          },
        },
        content: {
          dns: {
            title: 'Ok! Follow the instruction described below.',
            content: {
              spf: {
                existed: {
                  item1: `Edit the <span style="font-weight:bold">{{bold_text1}}</span> record and merge your existing
              record and merge your existing <span style="font-weight:bold">{{bold_text2}}</span> with variable display below`,
                  item2: `For example, if your domain already has the record:`,
                  item3: `So, created variable will look like this:`,
                },
                no_content: {
                  item1: `Great! Follow the instruction described below.`,
                  item2: `Create a new <span style="font-weight:bold">{{bold_text1}}</span> record`,
                  item3: `Fill in the <span style="font-weight:bold">{{bold_text1}}</span> field. Depending on the provider, you probably have to follow one of these options:`,
                  item4: `Leave it empty`,
                  item5: 'Fill it with @',
                  item6: 'Enter your domain name ({{domainName}})',
                  item7:
                    'Fill in the <span style="font-weight:bold">{{bold_text1}}</span> of this record with variable displayed below',
                },
                mainContent: {
                  item1: ` Check if you already have an <span style="font-weight:bold">{{bold_text1}}</span> record in your DNS. It should be a <span style="font-weight:bold">{{bold_text2}}</span> -type record with SPF variables (starts with "v=spf1"). This check will determine your next steps. record`,
                  item2: 'I did not find the record described above',
                  item3: 'I found the record described above',
                },
              },
              other: {
                keyword: `Create a new <span style="font-weight:bold">{{bold_text}}</span> record`,
                host: `Fill in the <span style="font-weight:bold">Name (Host)</span> field of this record with variable displayed below`,
                value: `Fill in the <span style="font-weight:bold">Value</span> field of this record with variable displayed below`,
              },
            },
          },
          step1: {
            title: 'Enter the domain you want to verify',
          },
          step2: {
            title: 'Log in to your domain provider',
            content: `To verify your domain, you need to set DNS records for your domain.`,
            list: {
              item1: `Open a new tab and navigate and <span style="font-weight:bold">{{bold_text}}</span>
                  to platform where your domain is hosted`,
              item2: `Find the screen where you can manage your domain. There will be an option to adjust your <span style="font-weight:bold">{{bold_text}}</span>.`,
              item3: `When you find it, you are ready for the next step!`,
            },
          },
          step3: {
            title: 'Add entries to your DNS settings',
            content: ` In your domain settings, you need to add record for {{domain_name}}.`,
          },
          step4: {
            title: 'Confirmation',
            content1: `Great! Your domain is verified! You can now enjoy the platform by sending your emails from any address
            associated with this domain.`,
            content2: `For example, you can send emails from company@ or info@. It is up to you!`,
          },
          step5: {
            title: 'Set default sender',
            content: `Default sender is an email address used in your emails as a 'From' address. We strongly recommend setting a default sender to help increase your deliverability performance.`,
          },
          step6: {
            title: 'Success!',
            content: `Great! Your domain is now verified, and the default sender is set.`,
          },
        },
      },
    },
    template: {
      messages: {
        update_success: 'Update template successfully',
        create_success: 'Create template successfully',
      },
    },
    contact: {
      tabs: {
        overview: 'Overview',
        groups: 'Groups',
        all_contacts: 'All contacts',
      },
      form: {
        create_group: 'Create group',
        group_name: 'Group name',
        group: 'Group',
        last_name: 'Last name',
        first_name: 'First name',
        step1: 'Add contact details',
        step2: 'Choose contact groups',
        each_one: 'Add each contact',
        import: 'Import list contact. Download example CSV file here',
        csv: {
          heading: 'Select CSV file',
          title: 'Contains 3 columns (last_name, first_name, email)',
          example: 'Example file',
          start: 'Start',
        },
        errors: {
          required: 'This is required field',
          select_file: 'Please select a file',
          select_group: 'Please select a contact group need append',
        },
        messages: {
          group_success: 'Create contact group successfully',
          contact_success: 'Create contact successfully',
          import_success: 'Import contact list successfully',
        },
      },
      table: {
        head: {
          recipient: 'Recipient',
          status: 'Status',
          name: 'Name',
          email: 'Email',
        },
        body: {
          active: 'Active',
          subscribe: 'Subscribe',
          unsubscribe: 'Unsubscribe',
          deactive: 'Deactive',
          deleted: 'Deleted',
        },
      },
      messages: {
        remove_contact_success: 'Removed contact successfully',
        remove_group_success: 'Removed group successfully',
      },
      overview: {
        summary: 'Summary',
        all_contacts: 'All contacts',
        new_contacts: 'New contacts',
        subscribed: 'Subscribed',
        contact_today: 'New contacts today',
        unsubscribed: 'Unsubscribed',
        open_rate: 'Avg. open rate',
        click_rate: 'Avg. click rate',
        recent_growth: 'Recent growth',
        change: `Changes in audience in last {{range}} days`,
        performance: 'Audience performance',
        growth: 'Growth contact',
        form: 'Build relationships. Grow your list with our features and create forms or landing pages to collect new contacts',
        data: 'Contact in day',
        dropdown: 'Last {{range}} days',
      },
    },
    campaign: {
      table: {
        head: {
          name: 'Name',
          delivered: 'Delivered',
          opened: 'Opened',
          clicked: 'Clicked',
        },
      },
      form: {
        send_to: 'Send to',
        summary: 'Summary',
        summary_content_b2c:
          'Your campaign will be sent to <span style="font-weight:bold;font-size:18px">{{recipients}}</span> recipients. The amount paid for the campaign is <span style="font-weight:bold;font-size:18px">{{money}}</span>',
        summary_content_b2b:
          'Your campaign will be sent to <span style="font-weight:bold;font-size:18px">{{recipients}}</span> recipients.',
        subject_content: 'Subject and content',
        fromEmail: 'From email',
        content: 'Content',
        template: 'Choose from templates saved on your account',
        setting: 'Settings and tracking',
        tracking: 'Tracking',
        campaignName: 'Campaign name',
        tracks: {
          open: {
            title: 'Find out exactly how many times a recipient opened a communication you sent.',
            content: 'Track opens',
          },
          click: {
            title: 'Track clicks',
            content:
              'Find out if the recipient is interested enough not only to open your campaign but also to engage with them.',
          },
          stats: {
            title: 'Get campaign statistics into your email inbox',
            content:
              'You will receive an email with overall campaign statistics such as open rate, click rate and bounce rate within 48 hours of sending.',
          },
        },
        errors: {
          required: 'This is a required field',
          space: 'Does not include spaces',
          group: 'Please select group need send',
          format: 'Invalid format',
          template: 'Please select template',
        },
      },
      messages: {
        update_success: 'Updated campaign successfully',
        create_success: 'Created campaign successfully',
      },
      schedule: {
        schedule_campaign: 'Schedule campaign',
        time_at: 'At what time?',
        times: 'How many times?',
        future_datetime: 'Please select future time',
        select_datetime: 'Please select date time',
        send_time: 'Send one time',
      },
    },
  },
  translate_catalog: {
    city: ['City', 'Province'],
    district: ['City', 'Ward', 'District', 'Town', 'District'],
    ward: ['Town', 'Commune', 'Ward'],
    street: ['Street', 'Street', 'Provincial Highway', 'Street', 'Highway', 'Avenue'],
    demands: ['Sell', 'Lease', 'Buy', 'Rent', 'Assign'],
    categories: {
      apartment: {
        title: 'Apartment',
        apartment: 'Apartment',
        duplex: 'Duplex',
        penthouse: 'Penthouse',
        serviced_apartment: ' Serviced apartment, mini',
        housing_project: 'Group, dormitory',
        officetel: 'Officetel',
      },
      house: {
        title: 'House',
        town_house: 'Street house, facade',
        alley_house: 'Lane house, alley',
        villa: 'Villa house',
        terrace_house: 'Townhouse',
      },
      land: {
        title: 'Land',
        industrial_land: 'Industrial land',
        agricultural_land: 'Agricultural land',
        residential_land: 'Residential land',
        ground_project: 'Project land',
      },
      commercial: {
        title: 'Office, Business premises',
        business_premises: 'Business premises',
        office: 'Office',
        shophouse: 'Shophouse',
        officetel: 'Officetel',
      },
      rooming_house: {
        title: 'Inn room',
        rooming_house: 'Inn room',
      },
    },
  },
  today: 'Today',
  empty_data: 'Empty',
  version: 'Version',
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
