import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';
// hooks
import useAuth from '../hooks/useAuth';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// utils
import { routesToPath } from '../utils/tenantUtils';

// ----------------------------------------------------------------------

// a function to retry loading a chunk to avoid chunk load error for out of date code
// Detail at https://www.codemzy.com/blog/fix-chunkloaderror-react
const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.indexOf('/dashboard') !== -1} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router({ deactivateRoutes = [] }) {
  // ====================== DETECT SOME TENANT ROUTES =======================

  const { user } = useAuth();

  const isAdminTenant = user?.package_using?.is_admin || false;

  // Only admin of package
  if (!isAdminTenant) {
    deactivateRoutes.push('/staff/list');
  }
  // ====================== END =======================

  const routes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'forget-password', element: <ForgetPassword /> },
        { path: 'reset/:token/', element: <ResetPassword /> },
        { path: 'staff/:token/', element: <StaffConfirm /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace />, index: true },
            { path: 'account', element: <UserAccount /> },
            { path: 'notification', element: <AllNotification /> },
          ],
        },
        {
          path: 'account',
          children: [
            { element: <Navigate to="/dashboard/account/list" replace />, index: true },
            { path: 'list', element: <SiteAccountList /> },
          ],
        },
        {
          path: 'post',
          children: [
            { element: <Navigate to="/dashboard/post/real/new" replace />, index: true },
            { path: 'list', element: <Posts /> },
            // { path: 'post/:title', element: <BlogPost /> },
            { path: 'real/new', element: <NewRealEstatePost /> },
            { path: 'real/:uuid/edit', element: <EditRealEstatePost /> },
            { path: 'social/new', element: <NewSocialPost /> },
            { path: 'social/:uuid/edit', element: <EditSocialPost /> },
            { path: 'web/new', element: <NewWebPost /> },
            { path: 'web/:uuid/edit', element: <EditWebPost /> },
            // { path: 'social/:uuid/edit', element: <InvoiceEdit /> },
          ],
        },
        {
          path: 'notification',
          children: [
            { element: <Navigate to="/dashboard/notification/list" replace />, index: true },
            { path: 'list', element: <Notifications /> },
            { path: 'create', element: <CreateNotification /> },
          ],
        },
        {
          path: 'quest',
          children: [
            { element: <Navigate to="/dashboard/quest/list" replace />, index: true },
            { path: 'list', element: <QuestList /> },
          ],
        },
        {
          path: 'email',
          children: [
            { element: <Navigate to="/dashboard/email/list" replace />, index: true },
            { path: 'list', element: <MailPage /> },
          ],
        },
        {
          path: 'staff',
          children: [
            { element: <Navigate to="/dashboard/staff/list" replace />, index: true },
            { path: 'list', element: <StaffList /> },
          ],
        },
        {
          path: 'campaign',
          children: [
            { element: <Navigate to="/dashboard/campaign/multi" replace />, index: true },
            { path: 'multi', element: <CampaignMultiChannel /> },
            { path: 'feed', element: <CampaignFeed /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Login />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];
  return useRoutes(routesToPath(routes, deactivateRoutes));
}

// AUTHENTICATION
const Login = Loadable(lazy(() => lazyRetry(() => import('../pages/auth/Login'))));
const ForgetPassword = Loadable(lazy(() => lazyRetry(() => import('../pages/auth/ForgetPassword'))));
const ResetPassword = Loadable(lazy(() => lazyRetry(() => import('../pages/auth/ResetPassword'))));
const StaffConfirm = Loadable(lazy(() => lazyRetry(() => import('../pages/auth/StaffConfirm'))));
// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/GeneralApp'))));
const GeneralAnalytics = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/GeneralAnalytics'))));

// POST
const Posts = Loadable(lazy(() => lazyRetry(() => import('../pages/posts/Posts'))));
const NewRealEstatePost = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/NewRealEstatePost'))));
const EditRealEstatePost = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/EditRealEstatePost'))));
const NewSocialPost = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/NewSocialPost'))));
const EditSocialPost = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/EditSocialPost'))));
const NewWebPost = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/NewWebPost'))));
const EditWebPost = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/EditWebPost'))));
// SITE ACCOUNT
const SiteAccountList = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/SiteAccountList'))));

// USER
const UserAccount = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/UserAccount'))));

// APP
const Calendar = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/Calendar'))));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/PermissionDenied'))));

// MAIN
const About = Loadable(lazy(() => lazyRetry(() => import('../pages/About'))));
const Faqs = Loadable(lazy(() => lazyRetry(() => import('../pages/Faqs'))));
const ComingSoon = Loadable(lazy(() => lazyRetry(() => import('../pages/ComingSoon'))));
const Maintenance = Loadable(lazy(() => lazyRetry(() => import('../pages/Maintenance'))));
const Pricing = Loadable(lazy(() => lazyRetry(() => import('../pages/Pricing'))));
const Payment = Loadable(lazy(() => lazyRetry(() => import('../pages/Payment'))));
const Page500 = Loadable(lazy(() => lazyRetry(() => import('../pages/Page500'))));
const Page403 = Loadable(lazy(() => lazyRetry(() => import('../pages/Page403'))));
const Page404 = Loadable(lazy(() => lazyRetry(() => import('../pages/Page404'))));

// NOTIFICATION
const Notifications = Loadable(lazy(() => lazyRetry(() => import('../pages/notifications/Notifications'))));
const AllNotification = Loadable(lazy(() => lazyRetry(() => import('../pages/notifications/AllNotification'))));
const CreateNotification = Loadable(lazy(() => lazyRetry(() => import('../pages/notifications/CreateNotification'))));

// QUEST
const QuestList = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/QuestList'))));

// STAFF
const StaffList = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/StaffList'))));

// EMAIL
const MailPage = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/Mail'))));

// Campaign
const CampaignMultiChannel = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/CampaignMultiChannel'))));
const CampaignFeed = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard/CampaignFeed'))));
