import { Grid, Stack, Typography, Button } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import PropTypes from 'prop-types';
import Iconify from './Iconify';
// components
import Image from './Image';
// hooks
import useResponsive from '../hooks/useResponsive';
import useLocales from '../hooks/useLocales';

export default function PopupRealTime({ isOpen, handleClose, popupContent, handleLinkingAccount }) {
  const isMobile = useResponsive('between', null, 0, 700);
  const { translate } = useLocales('modal');
  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={isOpen}>
        <Grid
          container
          sx={{
            maxWidth: '500px',
            backgroundColor: '#D6E4FF',
            px: '30px',
            py: '40px',
            borderRadius: '16px',
          }}
        >
          <Grid
            item
            xs={isMobile ? 12 : 10}
            sx={{
              color: '#000',
              fontSize: '14px',
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              width={'100%'}
              sx={{
                '& ul': {
                  ml: '25px',
                  li: {
                    mt: 1,
                  },
                },
              }}
            >
              <Stack justifyContent="center" alignItems="center" sx={{ mb: '8px' }}>
                <Iconify icon={'emojione:warning'} sx={{ width: '40px', height: '48px' }} />
              </Stack>
              <Typography
                variant="h4"
                sx={{ textAlign: 'center', fontWeight: '700', textTransform: 'uppercase', mt: '20px' }}
              >
                {translate('title.error_linking')}
              </Typography>
              <Typography variant="span" sx={{ textAlign: 'center', fontSize: '14px', mt: '20px' }}>
                {popupContent?.message}
              </Typography>
            </Stack>
            <Stack direction={'row'} mt={'20px'} justifyContent={'space-between'} alignItems={'center'}>
              <Button
                variant="text"
                size="large"
                onClick={() => {
                  handleClose();
                }}
                startIcon={<Iconify icon="material-symbols:arrow-back-ios-new" width={20} height={20} />}
                sx={{
                  px: '5px',
                  py: '10px',
                  height: '36px',
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: '#000',
                  fontSize: '0.875rem',
                  '&:hover': {
                    color: '#000',
                  },
                }}
              >
                {translate('btn.back')}
              </Button>
              <Button
                variant="text"
                size="large"
                onClick={handleLinkingAccount}
                endIcon={<Iconify icon="material-symbols:arrow-forward-ios-rounded" width={20} height={20} />}
                sx={{
                  px: '10px',
                  py: '5px',
                  height: '36px',
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: '#fff',
                  backgroundColor: '#3366FF',
                  fontSize: '0.875rem',
                  boxShadow: '0px 8px 16px rgba(0, 171, 85, 0.24)',
                  '&:hover': {
                    color: '#fff',
                    backgroundColor: '#3366FF',
                  },
                }}
              >
                {translate('popup.linking_again')}
              </Button>
            </Stack>
          </Grid>
          {isMobile ? (
            ''
          ) : (
            <Grid item xs={2}>
              <Image
                sx={{
                  width: '57px',
                }}
                visibleByDefault
                disabledEffect
                src="/assets/illustrations/illustration_annoy.svg"
                alt="login"
              />
            </Grid>
          )}
        </Grid>
      </Backdrop>
    </div>
  );
}

PopupRealTime.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  popupContent: PropTypes.object,
  handleLinkingAccount: PropTypes.func,
};
