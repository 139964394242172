import { PropTypes } from 'prop-types';
import { memo } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

SendNowIcon.propTypes = {
  color: PropTypes.string,
};

function SendNowIcon({ color, ...other }) {
  return (
    <Box {...other}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="40.000000pt"
        height="40.000000pt"
        viewBox="0 0 40.000000 40.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
        <g transform="translate(0.000000,40.000000) scale(0.007813,-0.007813)" fill={color || '#000000'} stroke="none">
          <path
            d="M2486 5094 c-21 -21 -26 -36 -26 -74 0 -38 5 -53 26 -74 21 -21 36
-26 74 -26 38 0 53 5 74 26 21 21 26 36 26 74 0 38 -5 53 -26 74 -21 21 -36
26 -74 26 -38 0 -53 -5 -74 -26z"
          />
          <path
            d="M1943 5050 c-146 -37 -253 -72 -383 -127 -521 -220 -965 -620 -1244
-1119 -159 -285 -268 -631 -307 -973 -18 -158 -7 -545 20 -694 101 -568 343
-1025 752 -1422 402 -390 905 -632 1459 -700 163 -20 477 -20 640 0 472 58
914 246 1290 548 212 170 444 435 585 667 181 296 307 660 350 1010 20 163 20
482 0 636 -79 597 -332 1106 -756 1519 -342 333 -740 553 -1196 661 -165 39
-220 29 -243 -42 -14 -42 2 -98 33 -119 12 -8 67 -24 122 -35 445 -96 859
-329 1185 -666 190 -196 311 -371 432 -621 156 -322 228 -642 228 -1013 0
-371 -74 -699 -229 -1016 -129 -265 -250 -433 -461 -644 -211 -211 -379 -332
-644 -461 -316 -155 -648 -230 -1016 -230 -522 0 -996 159 -1420 475 -122 91
-365 334 -456 456 -204 273 -356 601 -423 910 -69 322 -69 696 0 1020 48 222
173 528 298 729 179 289 460 572 742 750 237 149 514 261 779 316 41 9 84 22
96 30 32 21 48 77 34 119 -24 73 -86 82 -267 36z"
          />
          <path
            d="M569 3111 l-29 -29 0 -525 0 -525 31 -26 c41 -35 96 -35 138 0 l31
26 2 332 3 333 220 -320 c121 -176 235 -335 253 -353 28 -29 40 -34 78 -34 61
0 109 45 119 111 3 24 5 255 3 512 l-3 469 -33 29 c-42 38 -98 40 -139 5 l-28
-24 -5 -343 -5 -343 -243 352 c-134 193 -253 358 -265 367 -37 26 -94 19 -128
-14z"
          />
          <path
            d="M2053 3125 c-205 -56 -370 -224 -418 -427 -73 -312 113 -622 422
-702 77 -20 211 -20 288 -1 201 51 369 219 420 420 19 76 19 214 0 290 -51
201 -219 369 -420 420 -73 19 -222 18 -292 0z m299 -227 c79 -37 142 -98 181
-177 29 -60 32 -74 32 -161 0 -87 -3 -101 -32 -161 -39 -79 -102 -140 -181
-177 -49 -23 -70 -27 -152 -27 -82 0 -103 4 -152 27 -74 34 -152 112 -186 186
-23 49 -27 70 -27 152 0 78 4 104 23 144 45 99 125 172 225 208 77 27 194 21
269 -14z"
          />
          <path
            d="M2969 3111 c-22 -22 -29 -39 -29 -68 0 -21 43 -254 95 -518 l96 -480
34 -32 c30 -28 42 -33 86 -33 44 0 55 4 83 33 27 28 44 73 126 340 51 169 97
307 100 307 4 0 49 -138 100 -307 82 -269 98 -312 126 -340 28 -29 39 -33 82
-33 52 0 91 22 113 61 14 28 199 955 199 1002 0 87 -104 129 -170 70 -29 -27
-33 -40 -94 -343 -35 -173 -66 -308 -69 -300 -3 8 -45 141 -92 295 -47 154
-91 293 -97 308 -27 69 -114 90 -167 40 -24 -24 -42 -72 -116 -313 -48 -157
-92 -301 -98 -320 -9 -32 -15 -9 -62 230 -78 394 -73 376 -102 404 -38 38
-104 37 -144 -3z"
          />
          <path
            d="M4411 3114 l-31 -26 0 -323 0 -324 24 -28 c21 -24 32 -28 76 -28 44
0 55 4 76 28 l24 28 0 324 0 323 -31 26 c-21 18 -42 26 -69 26 -27 0 -48 -8
-69 -26z"
          />
          <path
            d="M4433 2175 c-40 -17 -53 -42 -53 -98 0 -38 5 -49 31 -71 21 -18 42
-26 69 -26 27 0 48 8 69 26 26 22 31 33 31 71 0 57 -14 81 -55 99 -42 17 -51
17 -92 -1z"
          />
        </g>
      </svg>
    </Box>
  );
}

export default memo(SendNowIcon);
