// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

// custom css
import './css/custom.css';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
// import { Auth0Provider } from '@auth0/auth0-react';
// Sentry Log
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// redux
import { persistor, store } from './redux/store';
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from './contexts/JWTContext';
// import { AuthProvider } from './contexts/Auth0Context';
// import { AuthProvider } from './contexts/FirebaseContext';
// import { AuthProvider } from './contexts/AwsCognitoContext';
import { WebSocketProvider } from './contexts/WSContext';

import { swUpdate } from './redux/slices/sw';
//
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// ----------------------------------------------------------------------
if (process.env.REACT_APP_SENTRY_DISABLE && JSON.parse(process.env.REACT_APP_SENTRY_DISABLE) === true) {
  console.log('disabled sentry');
} else {
  Sentry.init({
    dsn: 'https://eab7c9faa13f406f9227223e3f7be178@o4504009928540160.ingest.sentry.io/4504009931489280',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENVIRONMENT,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (typeof error === 'string' && error.indexOf('<!DOCTYPE html>') !== -1) {
        return null;
      }
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    release: process.env.REACT_APP_SENTRY_RELEASE_NAME,
  });
}

// Fix translate error from browser

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  // eslint-disable-next-line func-names
  Node.prototype.removeChild = function (child, ...res) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, [child, ...res]);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  // eslint-disable-next-line func-names
  Node.prototype.insertBefore = function (newNode, referenceNode, ...res) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, [newNode, referenceNode, ...res]);
  };
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <Auth0Provider
  //   domain={process.env.REACT_APP_AUTH0_DOMAIN}
  //   clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
  //   authorizationParams={{
  //     redirect_uri: window.location.origin,
  //   }}
  // >
  <AuthProvider>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <WebSocketProvider>
          <HelmetProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <CollapseDrawerProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </CollapseDrawerProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </HelmetProvider>
        </WebSocketProvider>
      </PersistGate>
    </ReduxProvider>
  </AuthProvider>
  // </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => store.dispatch(swUpdate({ payload: registration })),
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
