import PropTypes from 'prop-types';
import { forwardRef, useMemo } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Avatar as MUIAvatar, Box } from '@mui/material';
import useBoolean from '../hooks/useBoolean';

// ----------------------------------------------------------------------

const LazyAvatarCustom = styled(Box)(() => ({
  position: 'relative',
  width: 40,
  height: 40,
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '50%',
  justifyContent: 'center',
}));

const Avatar = forwardRef(({ color = 'default', children, sx, ...other }, ref) => {
  const imageError = useBoolean();
  const theme = useTheme();
  const { src } = other;

  const srcModified = useMemo(() => {
    if (src) {
      if (src?.indexOf('http') !== -1) {
        return `https://imagecdn.app/v2/image/${encodeURIComponent(src)}`;
      }
      return src;
    }
    return null;
  }, [src]);

  return srcModified && !imageError.value ? (
    <LazyAvatarCustom
      ref={ref}
      sx={{
        ...(color !== 'default' && {
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
        }),
        ...sx,
      }}
      {...other}
    >
      <Box
        component={LazyLoadImage}
        placeholderSrc="/assets/placeholder.svg"
        sx={{ position: 'absolute', top: 0, left: 0, width: 1, height: 1, objectFit: 'cover' }}
        src={srcModified}
        onError={imageError.onTrue}
      />
    </LazyAvatarCustom>
  ) : (
    <MUIAvatar
      ref={ref}
      sx={{
        ...(color !== 'default' && {
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </MUIAvatar>
  );
});

Avatar.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
};

export default Avatar;
